import styled from 'styled-components'
import { COLORS } from '../../../theme/css/global.colors'
import { device } from '../../.../../../theme/css/media.querys'

const ModalContainer = styled.div`
  .modal {
    @media ${device.ipad} {
      padding-left: 5px;
      padding-right: 5px;
    }
    &__close {
      position: absolute;
      right: -15px;
      top: -15px;
      button {
        cursor: pointer;
        font-weight: lighter;
        font-family: 'Raleway', sans-serif;
        color: ${COLORS.white};
        background-color: ${COLORS.salmon};
        border: none;
        padding: 8px;
        font-size: 18px;
        border-radius: 50%;
        width: 34px;
        height: 34px;
      }
    }
    &__body {
      img {
        display: block;
        width: 100%;
        max-width: 760px;
      }
    }
  }
`
export default ModalContainer
