import styled from 'styled-components'
import { COLORS } from '../../../theme/css/global.colors'
// import { device } from '../../.../../../theme/css/media.querys'

const Span = styled.span`
  color: ${COLORS.salmonText};
  font-size: 12px;
  margin-bottom: 5px;
`
export default Span
