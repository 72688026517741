import MetaTagsHome from './MetaTags'
import Navbar from '../../organisms/menu'
import TopSection from '../../organisms/home/TopSection'
import SecondSection from '../../organisms/home/SecondSection'
import ThirdSection from '../../organisms/home/ThirdSection'
import FourSection from '../../organisms/home/FourSection'
import FiveSection from '../../organisms/home/FiveSection'
import FeedBack from '../../organisms/home/FeedBack'
import Prensa from '../../organisms/home/Prensa'
import Footer from '../../organisms/footer'

const HomeTemplate = ({ urlNav }) => {
  return (
    <>
      <MetaTagsHome />
      <Navbar {...{ urlNav }} />
      <TopSection />
      <SecondSection />
      <ThirdSection />
      <FourSection />
      <FiveSection />
      <FeedBack />
      <Prensa />
      <Footer />
    </>
  )
}

export default HomeTemplate
