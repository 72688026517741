import styled from 'styled-components'
import { COLORS } from '../../../theme/css/global.colors'
import { device } from '../../../theme/css/media.querys'

const Title = styled.div`
  .text {
    font-size: ${props => (props.fontSize ? `${props.fontSize}px` : `${42}px`)};
    @media ${device.ipad} {
      font-size: ${props =>
        props.fontSize ? `${props.fontSize}px` : `${30}px`};
    }
    font-weight: 900;
    color: ${props =>
      props.colorTextFirst ? props.colorTextFirst : COLORS.violetDark};
    text-align: ${props => (props.textAlign ? props.textAlign : 'initial')};

    span {
      color: ${COLORS.salmon};
    }
  }
`
export default Title
