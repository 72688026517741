import styled from 'styled-components'
import { COLORS } from '../../../theme/css/global.colors'
import { device } from '../../../theme/css/media.querys'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .card {
    position: relative;
    -webkit-box-shadow: 0px 0px 20px 2px rgba(245, 212, 195, 1);
    -moz-box-shadow: 0px 0px 20px 2px rgba(245, 212, 195, 1);
    box-shadow: 0px 0px 20px 2px rgba(245, 212, 195, 1);
    border-radius: 17px;
    width: 100%;
    max-width: 690px;
    &__title {
      font-size: 5rem;
      text-align: center;
      color: #343453;
      margin-top: 55px;
    }
    &__subtitle {
      font-size: 2rem;
      color: ${COLORS.salmon};
      text-align: center;
      margin-top: 5px;
    }
    &__description {
      color: #313151;
      text-align: center;
      font-size: 18px;
      margin-bottom: 55px;
    }

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 100%;
      max-width: 150px;
      @media ${device.ipad} {
        display: none;
      }
    }

    &__imgLeft {
      left: 0;
    }
    &__imgRight {
      right: 0;
    }
  }
  .bgRight {
    position: absolute;
    right: 0;
    bottom: 400px;
    width: 150px;
    @media ${device.ipad} {
      display: none;
    }
  }
  .bgLeft {
    position: absolute;
    left: 0;
    top: 250px;
    width: 100px;
    @media ${device.ipad} {
      display: none;
    }
  }
`
export default Wrapper
