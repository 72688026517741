// Molecules
import ArrowScrollDown from '../../molecules/ArrowScrollDown'
// Utils
import CongeladosPie from '../../../assets/images/png/congelados/congelados-pie.png'
import { COLORS } from '../../../theme/css/global.colors'
import { PreparacionDiv } from './styles.js'
const Preparacion = () => {
  return (
    <PreparacionDiv id='preparacion'>
      <ArrowScrollDown
        bgColor={COLORS.white}
        color={COLORS.salmon}
        sectionId='preparacion'
      />
      <div className='wrapper'>
        <div className='content'>
          <h2 className='content__title'>¿Cómo Preparar?</h2>
          <ul className='content__list'>
            <li>
              <strong>Microondas:</strong>
              Uno de los modos más rápidos y convenientes. Simplemente coloca el
              plato congelado en el microondas siguiendo las instrucciones del
              envase o la receta. Asegúrate de remover cualquier envoltorio
              metálico y cubre la comida con una tapadera o film transparente
              para mantener la humedad. Calienta en intervalos cortos para
              evitar que se recaliente y revuelve ocasionalmente para obtener
              cocción uniforme.
            </li>
            <li>
              <strong>Horno convencional:</strong>
              Esta opción es ideal para platos que necesitan una cocción más
              lenta y crujiente, como pizzas, empanadas o lasañas. Precalienta
              el horno a la temperatura indicada en el envase y coloca el plato
              congelado directamente en la bandeja del horno o en un recipiente
              apto para horno. Sigue las instrucciones de tiempo y temperatura
              para lograr un resultado delicioso y dorado.
            </li>
            <li>
              <strong>Estufa o sartén:</strong>
              Utiliza este método para platos que requieren salteado o cocción
              en sartén, como salteados de verduras o fideos. Vierte una pequeña
              cantidad de aceite o agua en el sartén y calientalo a fuego medio.
              Agrega el plato congelado y cocina, removiendo constantemente
              hasta que esté completamente caliente.
            </li>
          </ul>
          <div className='content__image'>
            <img
              src={CongeladosPie}
              alt='Cuanto tiempo dura la comida congelada en el congelador'
            />
          </div>
        </div>
      </div>
    </PreparacionDiv>
  )
}

export default Preparacion
