import Navbar from '../../organisms/menu'
import Cesta from '../../organisms/cesta'
import AccountForm from '../../organisms/AccountForm'
import Footer from '../../organisms/footer'
const CreateAccountTemplate = () => {
  return (
    <>
      <Navbar />
      <Cesta />
      <div className='wrapper'>
        <AccountForm />
      </div>
      <Footer />
    </>
  )
}

export default CreateAccountTemplate
