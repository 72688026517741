// Icons
import IconDown from '../../../assets/images/svg/icons/icon-arrow-down.svg'
// Styles
import Arrow from './styles'

const ArrowScrollDown = ({ bgColor, customStyles, sectionId }) => {
  const handleClickScroll = () => {
    const element = document.getElementById(sectionId)
    element.scrollIntoView({
      offset: 140,
      behavior: 'smooth',
      inline: 'nearest',
    })
  }

  return (
    <Arrow
      bgColor={bgColor}
      style={customStyles && customStyles}
      onClick={() => handleClickScroll()}
    >
      <img src={IconDown} />
    </Arrow>
  )
}

export default ArrowScrollDown
