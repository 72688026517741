import Slide1 from '../../../assets/images/png/slider/slideProduct1.png'
import Slide2 from '../../../assets/images/png/slider/slideProduct2.png'
import Slide3 from '../../../assets/images/png/slider/slideProduct3.png'
import Slide4 from '../../../assets/images/png/slider/slideProduct4.png'
import Slide1Mobile from '../../../assets/images/png/slider/slideProduct1Mobile.png'
import Slide2Mobile from '../../../assets/images/png/slider/slideProduct2Mobile.png'
import Slide3Mobile from '../../../assets/images/png/slider/slideProduct3Mobile.png'
import Slide4Mobile from '../../../assets/images/png/slider/slideProduct4Mobile.png'
import SlideArroz from '../../../assets/images/png/slider/slide-arroz.png'

// Icons
import Tallarines from '../../../assets/images/svg/icons/tallarines.svg'
import Tomate from '../../../assets/images/svg/icons/tomate.svg'
import Congelado from '../../../assets/images/svg/icons/congelados.svg'
import Envase from '../../../assets/images/svg/icons/envase.svg'
import Arroz from '../../../assets/images/svg/icons/iconos_arroz_slider.svg'

export const BANNER_INFO = [
  {
    title: 'Sabores del mundo en arroz',
    description:
      'Descubre el auténtico sabor de los arroces internacionales, conservados a la perfección con la mejor técnica de congelación y entregados directamente a tu mesa a través de nuestro eficaz delivery.',
    banner: SlideArroz,
    bannerMobile: SlideArroz,
    colorText: '#E7E1C9',
    txtBtn: '¡A comer!',
    bgColorBtn: '#CE6C61',
    colorTxtBtn: '#FFFFFF',
    icon: <Arroz />,
    category: 'Arroces',
  },
  {
    title: 'Platos preparados a domicilio',
    description:
      'Platos caseros que llegan directamente a tu mesa con todas sus propiedades y con el frescor de su sabor natural gracias a un eficiente proceso de congelado y cadena de distribución.',
    banner: Slide1,
    bannerMobile: Slide1Mobile,
    colorText: '#262C57',
    txtBtn: '¡A comer!',
    bgColorBtn: '#FFFFFF',
    colorTxtBtn: '#262C57',
    icon: <Tallarines />,
    category: null,
  },
  {
    title: 'Comida casera directamente a tu mesa',
    description:
      'La vistosidad y atractivo de nuestros platos radica en el color de sus ingredientes, cuidadosamente seleccionados para lograr en nuestras recetas un penetrante olor y sabor inigualables.',
    banner: Slide2,
    bannerMobile: Slide2Mobile,
    colorText: '#FFFFFF',
    txtBtn: '¡Me dio hambre!',
    bgColorBtn: '#DA7466',
    colorTxtBtn: '#FFFFFF',
    icon: <Tomate />,
    category: null,
  },
  {
    title: 'Receta, ingredientes y sabor inigualable',
    description:
      'Como lo oyes, nuestros congelados no son cualquier congelado. No tenemos procesos industriales, nuestra cadena de frío es custodiada por nosotros mismos para conservar el máximo sabor y calidad de nuestros platos caseros.',
    banner: Slide3,
    bannerMobile: Slide3Mobile,
    colorText: '#262C57',
    txtBtn: 'Quiero pedir...',
    bgColorBtn: '#275C73',
    colorTxtBtn: '#FFFFFF',
    icon: <Congelado />,
    category: null,
  },
  {
    title: 'Envases que conversan mejor tus alimentos',
    description:
      'Nuestras cajas, confeccionadas con materiales amigables con el medio ambiente, ofrecen innumerables ventajas, desde la óptima conservación de los alimentos hasta su práctico uso como plato y su fácil reciclado.',
    banner: Slide4,
    bannerMobile: Slide4Mobile,
    colorText: '#262C57',
    txtBtn: '¡A comer!',
    bgColorBtn: '#FFFFFF',
    colorTxtBtn: '#262C57',
    icon: <Envase />,
    category: null,
  },
]
