import Navbar from '../../organisms/menu'
import Cesta from '../../organisms/cesta'
import AddressForm from '../../organisms/addressForm'
import SelectAddress from '../../organisms/profile/SelectAddress'
import ResumeCart from '../../organisms/resumeCart'
import Footer from '../../organisms/footer'
// Styles
import Container from './styles'

const DeliveryTemplate = () => {
  const dataUser = JSON.parse(sessionStorage.getItem('dataUser'))
  return (
    <>
      <Navbar />
      <Cesta />
      <div className='wrapper'>
        <Container>
          {dataUser ? <SelectAddress /> : <AddressForm />}
          <ResumeCart />
        </Container>
      </div>
      <Footer />
    </>
  )
}

export default DeliveryTemplate
