import { useState } from 'react'
// Redux
import { useDispatch } from 'react-redux'
import { addProduct } from '../../../../redux/productosSlice'
// Molecules
import ButtonAddProduct from '../../../molecules/ButtonAddProduct'
// Hooks
import { useNotify } from '../../../../hooks/useToastNotification'
// Utils
import { formatPriceCLP } from '../../../../utils/formats'
// Styles
import Card from './styles'
import { Link } from 'react-router-dom'
import ControlsAddOrRemove from '../../../molecules/ControlsAddOrRemove'

const ProductCard = product => {
  const dispatch = useDispatch()
  const { title, description, image, price, alt, slug } = product.data
  const [quantity, setQuantity] = useState(1)

  const handleClickAddProduct = () => {
    const params = {
      title,
      description,
      image,
      price,
      quantity,
    }
    dispatch(addProduct(params))
    useNotify({ message: 'Producto guardado!', type: 'success', close: 2000 })
  }

  return (
    <Card className='cardProduct'>
      <div className='cardProduct__header'>
        <div className='cardProduct__cover'>
          <Link to={`/shop/${slug}`}>
            <img
              src={`${process.env.PUBLIC_URL}${image}`}
              className='cardProduct__cover-image'
              alt={alt}
            />
          </Link>
        </div>
        <div className='cardProduct__attributes'>
          <Link to={`/shop/${slug}`}>
            <h3 className='cardProduct__attributes-title'>{title}</h3>
          </Link>
          <p className='cardProduct__attributes-description'>{description}</p>
        </div>
      </div>
      <div className='cardProduct__sizePeople'>
        <span className='cardProduct__sizePeople-price'>
          {formatPriceCLP(price)}
        </span>
      </div>
      <div className='cardProduct__controlsBox'>
        <ControlsAddOrRemove setQuantity={setQuantity} quantity={quantity} />
        <ButtonAddProduct onClick={handleClickAddProduct} />
      </div>
    </Card>
  )
}

export default ProductCard
