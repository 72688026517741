import styled from 'styled-components'
import { COLORS } from '../../../theme/css/global.colors'
// import { device } from '../../.../../../theme/css/media.querys'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  .link {
    color: ${COLORS.salmon};
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
  }
`
export default Form
