import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// Molecules
import InputForm from '../../molecules/InputForm'
import Button from '../../molecules/Button'
import ErrorLabel from '../../molecules/ErrorLabel'
// Hooks
import { useNotify } from '../../../hooks/useToastNotification'
// Utils
import { isEmailValid } from '../../../utils/validators'
// Styles
import { COLORS } from '../../../theme/css/global.colors'
import Form from './styles'

const AccountForm = () => {
  const navigate = useNavigate()
  // States form
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  // Valid states
  const [emailError, setEmailError] = useState(null)
  const [nameError, setNameError] = useState(null)
  const [lastNameError, setLastNameError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [passwordRepeatError, setPasswordRepeatError] = useState(null)

  const handleSubmitCreateAccount = e => {
    e.preventDefault()
    handleValidateInputs()
  }

  const handleValidateInputs = () => {
    isEmailValid(email) ? setEmailError(false) : setEmailError(true)
    name === '' ? setNameError(true) : setNameError(false)
    lastName === '' ? setLastNameError(true) : setLastNameError(false)
    phone === '' ? setPhoneError(true) : setPhoneError(false)
    password === '' ? setPasswordError(true) : setPasswordError(false)
    repeatPassword === ''
      ? setPasswordRepeatError(true)
      : setPasswordRepeatError(false)
    if (password !== '' && repeatPassword !== '') {
      // eslint-disable-next-line no-unused-expressions
      password === repeatPassword
        ? (setPasswordError(false), setPasswordRepeatError(false))
        : (setPasswordError(true), setPasswordRepeatError(true))
    }
  }

  useEffect(() => {
    if (
      emailError === false &&
      nameError === false &&
      lastNameError === false &&
      phoneError === false &&
      passwordError === false &&
      passwordRepeatError === false
    ) {
      fetch(
        `https://cheffify.cl/administrator/login/create.php?email=${email}&name=${name}&lastName=${lastName}&phone=${phone}&password=${password}`
      )
        // Exito
        .then(response => response.json()) // convertir a json
        .then(json => {
          json.status === 0 &&
            useNotify({
              message: 'Ups ha ocurrido un error!',
              type: 'error',
              close: 2000,
            })
          json.status === 1 &&
            useNotify({
              message: 'Esta cuenta ya esta registrada',
              type: 'error',
              close: 2000,
            })
          if (json.status === 2) {
            useNotify({
              message:
                'Hemos enviado un mensaje de validación a tu email. Para activar tu cuenta debes verificarlo. Si no lo recibiste revisa la carpeta de spam.',
              type: 'success',
              close: 10000,
            })
            setTimeout(() => {
              navigate('/login')
            }, 10000)
          }
        })
        .catch(err => console.log('Solicitud fallida', err))
    }
  }, [
    emailError,
    nameError,
    lastNameError,
    phoneError,
    passwordError,
    passwordRepeatError,
  ])

  return (
    <Form>
      <InputForm
        type='text'
        name='emailUser'
        id='emailUser'
        placeholder='Email'
        onChangeInput={setEmail}
        error={emailError}
      />
      {emailError === true && <ErrorLabel text='Ingrese un formato correcto' />}
      <InputForm
        type='text'
        name='nameUser'
        id='nameUser'
        placeholder='Nombre'
        onChangeInput={setName}
        error={nameError}
      />
      {nameError === true && <ErrorLabel text='Ingrese nombre' />}
      <InputForm
        type='lastName'
        name='lastNameUser'
        id='lastNameUser'
        placeholder='Apellido'
        onChangeInput={setLastName}
        error={lastNameError}
      />
      {lastNameError === true && <ErrorLabel text='Ingrese apellido' />}
      <InputForm
        type='text'
        name='phone'
        id='phone'
        placeholder='Celular'
        onChangeInput={setPhone}
        error={phoneError}
      />
      {phoneError === true && <ErrorLabel text='Ingrese celular' />}
      <InputForm
        type='password'
        name='passwordUser'
        id='passwordUser'
        placeholder='Contraseña'
        onChangeInput={setPassword}
        error={passwordError}
      />
      {passwordError === true && (
        <ErrorLabel
          text={`${
            password !== repeatPassword
              ? 'Contraseñas no coinciden'
              : 'Ingresa password'
          }`}
        />
      )}
      <InputForm
        type='password'
        name='passwordUserRepeat'
        id='passwordUserRepeat'
        placeholder='Repetir Contraseña'
        onChangeInput={setRepeatPassword}
        error={passwordRepeatError}
      />
      {passwordRepeatError === true && (
        <ErrorLabel
          text={`${
            password !== repeatPassword
              ? 'Contraseñas no coinciden'
              : 'Ingresa password'
          }`}
        />
      )}
      <Button
        text='Crear cuenta'
        fontSize={17}
        color={COLORS.white}
        bgColor={COLORS.salmon}
        borderColor={COLORS.salmon}
        borderRadius={6}
        onClick={handleSubmitCreateAccount}
      />
      <Link to='/login' className='link'>
        Iniciar sesión
      </Link>
    </Form>
  )
}

export default AccountForm
