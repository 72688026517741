import Icon from 'cheffify-react-icons'
// Styled
import Button from './styles'
const ButtonAddProduct = ({ text = 'Agregar', onClick }) => {
  return (
    <Button className='cardProduct__controlsBox-btnAdd' onClick={onClick}>
      {text}
      <Icon name='cart' size={15} />
    </Button>
  )
}

export default ButtonAddProduct
