// Molecules
import Button from '../../molecules/Button'
// Utils
import { COLORS } from '../../../theme/css/global.colors'
import BannerImage from '../../../assets/images/png/banners/banner-congelados.png'
import { BannerDiv } from './styles.js'

const Banner = () => {
  const handleClickScroll = () => {
    const element = document.getElementById('sectionVentajas')
    element.scrollIntoView({
      offset: 140,
      behavior: 'smooth',
      inline: 'nearest',
    })
  }

  return (
    <BannerDiv bgImage={BannerImage}>
      <h1 className='title'>¿Qué sabes sobre congelados?</h1>
      <p className='description'>
        Los platos congelados ofrecen una solución conveniente para quienes
        tienen un estilo de vida ocupado. Disfruta de delicionas comidas
        preparadas y conserva los nutrientes de forma práctica y sabrosa. Una
        opción versátil que ahorra tiempo y reduce el desperdicio de alimentos.
      </p>
      <Button
        text='Saber más'
        fontSize={17}
        color={COLORS.white}
        bgColor={COLORS.salmon}
        width={240}
        borderColor={COLORS.salmon}
        margin={5}
        onClick={handleClickScroll}
      />
    </BannerDiv>
  )
}

export default Banner
