import styled from 'styled-components'
import { COLORS } from '../../../theme/css/global.colors'

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: none;
  color: ${COLORS.white};
  border-radius: 5px;
  background-color: ${COLORS.salmon};
  width: 100%;
  max-width: 170px;
  height: 45px;
  margin-left: 7px;
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
`
export default Button
