import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// Molecules
import InputForm from '../../molecules/InputForm'
import Button from '../../molecules/Button'
import ErrorLabel from '../../molecules/ErrorLabel'
// Hooks
import { useNotify } from '../../../hooks/useToastNotification'
// Utils
import { isEmailValid } from '../../../utils/validators'
// Styles
import { COLORS } from '../../../theme/css/global.colors'
import Form from './styles'

const LoginForm = () => {
  const navigate = useNavigate()

  const [emailLogin, setEmailLogin] = useState('')
  const [passwordLogin, setPasswordLogin] = useState('')
  // Inputs Valids
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)

  const handleSubmitLogin = e => {
    e.preventDefault()
    handleValidateInputs()
  }

  const handleValidateInputs = () => {
    isEmailValid(emailLogin) ? setEmailError(false) : setEmailError(true)
    passwordLogin === '' ? setPasswordError(true) : setPasswordError(false)
  }

  useEffect(() => {
    if (emailError === false && passwordError === false) {
      fetch(
        `https://cheffify.cl/administrator/login/session.php?email=${emailLogin}&password=${passwordLogin}`
      )
        // Exito
        .then(response => response.json()) // convertir a json
        .then(result => {
          if (result.status === 1) {
            sessionStorage.setItem('dataUser', JSON.stringify(result.user))
            useNotify({
              message: 'Sesión iniciada!, serás redirigido en unos segundos...',
              type: 'success',
              close: 2000,
            })
            setTimeout(() => {
              navigate('/perfil')
            }, 2000)
          }
          if (result.status === 0) {
            useNotify({
              message: 'Datos incorrectos',
              type: 'error',
              close: 2000,
            })
          }
        })
        .catch(err => console.log('Solicitud fallida', err))
    }
  }, [emailError, passwordError])

  return (
    <Form>
      <InputForm
        type='email'
        name='emailUser'
        id='emailUser'
        placeholder='Email'
        onChangeInput={setEmailLogin}
        error={emailError}
      />
      {emailError === true && <ErrorLabel text='Ingrese un formato correcto' />}
      <InputForm
        type='password'
        name='passwordUser'
        id='passwordUser'
        placeholder='Contraseña'
        onChangeInput={setPasswordLogin}
        error={passwordError}
      />
      {passwordError === true && <ErrorLabel text='Campo vacío' />}
      <Button
        text='Login'
        fontSize={17}
        color={COLORS.white}
        bgColor={COLORS.salmon}
        borderColor={COLORS.salmon}
        borderRadius={6}
        onClick={handleSubmitLogin}
      />
      <Link to='/crear-cuenta' className='link'>
        Crear cuenta
      </Link>
    </Form>
  )
}

export default LoginForm
