import { Helmet } from 'react-helmet-async'

const MetaTagsHome = () => {
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>Comida congelada a domicilio | Platos Congelados Cheffify</title>
      <meta
        name='description'
        content='Platos saludables y deliciosos, preparados y
congelados para tu comodidad. ¡Disfruta de comida casera sin el
trabajo de cocinar!'
      />
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Cheffify",
          "url": "https://www.cheffify.cl/",
          "logo": "https://www.cheffify.cl/static/media/cheffify.e328612655189f24c7edf805b454c224.svg",
          "sameAs": "cheffify_cl/"
         }     
    `}</script>
      <script type='application/ld+json'>{`
        {
         "@context": "https://schema.org/", 
         "@type": "Product", 
         "name": "Albóndigas de carne",
         "image": "https://www.cheffify.cl/static/media/albondigasdecarne.9f6763f74480029d3ad5.jpg",
         "description": "Receta familiar jugosa",
         "brand": {
           "@type": "Brand",
           "name": "Cheffify"
         },
         "offers": {
           "@type": "Offer",
           "url": "https://www.cheffify.cl/",
           "priceCurrency": "CLP",
           "price": "4450"
          }
         }       
    `}</script>
      <script type='application/ld+json'>{`
        {
         "@context": "https://schema.org/", 
         "@type": "Product", 
         "name": "Asado alemán con salsa mostaza",
         "image": "https://www.cheffify.cl/static/media/asadoalemanmostaza.e957b5162c0e17a7cbb4.jpg",
         "description": "Asado jugoso y con carne de altísima calidad, salsa de mostaza liviana, le da un sabor especial, nos encanta.",
         "brand": {
           "@type": "Brand",
           "name": "Cheffify"
         },
         "offers": {
           "@type": "Offer",
           "url": "https://www.cheffify.cl/",
           "priceCurrency": "CLP",
           "price": "4450"
          }
         }     
    `}</script>
      <script type='application/ld+json'>{`
        {
         "@context": "https://schema.org/", 
         "@type": "Product", 
         "name": "Lomo de cerdo con cebolla caramelizada",
         "image": "https://www.cheffify.cl/static/media/lomodecerdo.c58481d68ee48a6f3dc1.jpg",
         "description": "Es de nuestros platos favoritos, tratamos a la cebolla con tanto cariño dejando solo ese sabor dulce y suave para acompañarlo en su perfecta cocción.",
         "brand": {
           "@type": "Brand",
           "name": "Cheffify"
         },
         "offers": {
           "@type": "Offer",
           "url": "https://www.cheffify.cl/",
           "priceCurrency": "CLP",
           "price": "4450"
          }
         }     
    `}</script>
      <script type='application/ld+json'>{`
        {
         "@context": "https://schema.org/", 
         "@type": "Product", 
         "name": "Strogonoff de carne",
         "image": "https://www.cheffify.cl/static/media/strogonoffcarne.46a0f651f55f05877dae.jpg",
         "description": "Carne sin grasa y el corte es perfecto, la salsa lo acompaña muy bien, acompañalo con arroz blanco",
         "brand": {
           "@type": "Brand",
           "name": "Cheffify"
         },
         "offers": {
           "@type": "Offer",
           "url": "https://www.cheffify.cl/",
           "priceCurrency": "CLP",
           "price": "4450"
          }
         }    
    `}</script>
    </Helmet>
  )
}

export default MetaTagsHome
