import Navbar from '../../organisms/menu'
import Title from '../../atoms/title'
import Footer from '../../organisms/footer'

const BuyFailedTemplate = () => {
  return (
    <>
      <Navbar />
      <div className='wrapper'>
        <Title
          title='Ha ocurrido un error con su compra, favor intente en otro momento.'
          fontSize={24}
          color='#D77466'
        />
      </div>
      <Footer />
    </>
  )
}

export default BuyFailedTemplate
