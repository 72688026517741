import Icon from 'cheffify-react-icons'
// Redux
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
// Utils
import { totalItemsProducts } from '../../../utils/functions'
// Icon Log Out
// Styles
import Wrapper from './styles'

const BtnAccount = ({
  text,
  bgColor,
  iconLeft,
  iconRight,
  iconColor,
  sizeIcon,
  onClickCesta,
}) => {
  const dataCesta = useSelector(state => state.products.dataCesta)
  const dataUser = sessionStorage.getItem('dataUser')
  return (
    <Wrapper bgColor={bgColor}>
      <Link to={dataUser === null ? '/login' : '/perfil'} className='link'>
        <Icon
          name={iconLeft}
          size={sizeIcon}
          color={iconColor}
          className='icon'
        />
        <p className='text'>{text}</p>
      </Link>
      <div className='separator' />
      <div className='boxIcon' onClick={onClickCesta}>
        <Icon
          name={iconRight}
          size={sizeIcon}
          color={iconColor}
          className='icon'
        />
      </div>

      {dataCesta.length > 0 && (
        <span className='notification'>{totalItemsProducts(dataCesta)}</span>
      )}
    </Wrapper>
  )
}

export default BtnAccount
