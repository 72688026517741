import { useEffect } from 'react'
// Redux
import { useDispatch } from 'react-redux'
import { hideCesta } from '../redux/productosSlice'
import DeliveryTemplate from '../components/templates/Delivery'

const Delivery = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(hideCesta())
  }, [])

  return <DeliveryTemplate />
}

export default Delivery
