import { useState, useEffect } from 'react'
import Title from '../../atoms/title'
// Utils
import { formatPriceCLP } from '../../../utils/formats'
// Styles
import { Table } from './styles'

const TableOrder = () => {
  const [orders, setOrders] = useState([])

  const dataUser = JSON.parse(sessionStorage.getItem('dataUser'))

  const handleClickCollapse = id => {
    const accordion = document.getElementById(`accordion${id}`)
    accordion.style.display = 'block'
  }

  const handleFetchOrders = () => {
    const idUser = dataUser.id
    fetch(
      `https://cheffify.cl/administrator/login/get_transactions.php?id=${idUser}`
    )
      // Exito
      .then(response => response.json()) // convertir a json
      .then(result => {
        setOrders(result)
      })
      .catch(err => console.log('Solicitud fallida', err))
  }

  const handleRepeatBuy = ({
    monto,
    idUser,
    name,
    email,
    phone,
    comuna,
    address,
    products,
  }) => {
    const isConfirm = confirm('¿Estás seguro de comprar nuevamente?')
    isConfirm === true &&
      sendBuy({ monto, idUser, name, email, phone, comuna, address, products })
  }

  const sendBuy = ({
    monto,
    idUser,
    name,
    email,
    phone,
    comuna,
    address,
    products,
  }) => {
    window.location.href = `https://cheffify.cl/transbank/transbank.php?action=create&amount=${monto}&idUser=${idUser}&address=${address}&name=${name}&lastName=${name}&email=${email}&comuna=${comuna}&phone=${phone}&products=${JSON.stringify(
      products
    )}`
  }

  useEffect(() => {
    handleFetchOrders()
  }, [])

  return orders.length > 0 ? (
    <Table>
      <div>
        <div className='row'>
          <p className='row__title'>Fecha del pedido</p>
          <p className='row__title'>Valor</p>
          <p className='row__title'>&#32;</p>
        </div>
        {orders &&
          orders.map(order => {
            return (
              <div className='row' key={order.id}>
                <div className='row__content'>
                  <div className='row__content-info'>
                    <p className='row__content-info-txt'>
                      {order.transactionDate}
                    </p>
                    <p className='row__content-info-txt'>
                      {formatPriceCLP(order.monto)}
                    </p>
                    <p className='row__content-info-txt'>
                      <button onClick={() => handleClickCollapse(order.id)}>
                        Detalles
                      </button>
                    </p>
                  </div>
                  <div
                    className='row__content-accordion'
                    id={`accordion${order.id}`}
                  >
                    {order.products.map((product, index) => (
                      <div className='box' key={index}>
                        <div className='box__left'>
                          <img
                            src={`${process.env.PUBLIC_URL}${product.image}`}
                            alt={`Imagen de ${product.title}`}
                          />
                          <p>{product.title}</p>
                        </div>
                        <div className='box__right'>
                          <p>{formatPriceCLP(product.price)}</p>
                        </div>
                      </div>
                    ))}
                    <button
                      className='row__content-accordion-btn'
                      onClick={() =>
                        handleRepeatBuy({
                          monto: order.monto,
                          idUser: order.id_user,
                          name: order.name,
                          email: order.email,
                          phone: order.telefono,
                          comuna: order.comuna,
                          address: order.address,
                          products: order.products,
                        })
                      }
                    >
                      Repetir pedido
                    </button>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </Table>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Title
        title='No se han realizado compras por el momento'
        fontSize={18}
        color='#D77466'
      />
    </div>
  )
}

export default TableOrder
