import Navbar from '../../organisms/menu'
import Title from '../../atoms/title'
import Footer from '../../organisms/footer'

const BuySuccessTemplate = () => {
  return (
    <>
      <Navbar />
      <div className='wrapper'>
        <Title
          title='¡Su compra ha finalizado con éxito!'
          fontSize={24}
          color='#D77466'
        />
        <p>Revise su correo eléctronico, para mas detalle.</p>
      </div>
      <Footer />
    </>
  )
}

export default BuySuccessTemplate
