// Styles
import Container from './styles'
const ControlsAddOrRemove = ({ setQuantity, quantity }) => {
  const handleLessQuantity = () => {
    setQuantity(quantity + 1)
  }

  const handleMoreQuantity = () => {
    quantity > 0 && setQuantity(quantity - 1)
  }

  return (
    <Container>
      <span className='quantityNumber'>{quantity}</span>
      <div className='controlBox'>
        <button className='controlBox__btn' onClick={handleLessQuantity}>
          +
        </button>
        <button className='controlBox__btn' onClick={handleMoreQuantity}>
          -
        </button>
      </div>
    </Container>
  )
}

export default ControlsAddOrRemove
