import { useState } from 'react'
import Navbar from '../../organisms/menu'
import Cesta from '../../organisms/cesta'
import CarouselProductos from '../../molecules/Carousels/CarouselProducts'
import Filter from '../../organisms/filter'
import ProductsFilter from '../../organisms/products/productsFilter'
import ComoFunciona from '../../organisms/products/ComoFunciona'
import Somos from '../../organisms/products/somos'
import Footer from '../../organisms/footer'
import MetaTagsShop from './MetaTags'

const ShopTemplate = ({ urlBack }) => {
  const [filtersActive, setFiltersActive] = useState({
    category: '',
    price: [1000, 17000],
    format: [],
  })
  return (
    <>
      <MetaTagsShop />
      <Navbar {...{ urlBack }} />
      <Cesta />
      <div className='wrapper'>
        <CarouselProductos {...{ filtersActive, setFiltersActive }} />
        <div className='container'>
          <Filter {...{ filtersActive, setFiltersActive }} />
          <ProductsFilter />
        </div>
        <ComoFunciona />
        <Somos />
      </div>
      <Footer />
    </>
  )
}

export default ShopTemplate
