import { useEffect } from 'react'
import { Link } from 'react-router-dom'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import { showOrHideCesta } from '../../../redux/productosSlice'
// Molecules
import Button from '../../molecules/Button'
import BtnScrollUp from '../../molecules/BtnScrollUp'
// Toast Notification
import { ToastContainer } from 'react-toastify'
// SVG
import Logo from '../../../assets/images/svg/logos/cheffify.svg'
import IconPhone from '../../../assets/images/svg/icons/icon-phone-color.svg'
import IconIns from '../../../assets/images/svg/icons/icon-inst.svg'
import IconMail from '../../../assets/images/svg/icons/icon-mail.svg'
import IconTel from '../../../assets/images/svg/icons/icon-phone.svg'
// PDF DOC
import FilePDF from '../../../assets/docs/pdf/politicas-de-privacidad-cheffify.pdf'
// Utils
import { COLORS } from '../../../theme/css/global.colors'
// Styles
import FooterBox from './styles'

const Footer = () => {
  const dispatch = useDispatch()
  const dataCesta = useSelector(state => state.products.dataCesta)

  const handleClickWhatsApp = () => {
    window.open('https://wa.link/z9otdj', '_blank', 'noreferrer')
  }

  useEffect(() => {
    dataCesta.length > 0 && dispatch(showOrHideCesta())
  }, [])

  return (
    <FooterBox>
      <hr />
      <div className='footer'>
        <div className='footer__top'>
          <Link to='/'>
            <img
              src={Logo}
              className='footer__top-logo'
              alt='Logo Cheffify Footer'
            />
          </Link>
          <Button
            text='Pide por Whatsapp'
            fontSize={16}
            color={COLORS.aqua}
            bgColor={COLORS.white}
            borderColor={COLORS.aqua}
            iconLeft={IconPhone}
            iconLeftSize={25}
            width={270}
            onClick={handleClickWhatsApp}
          />
        </div>
        <div className='footer__middle'>
          <p>©2023 cheffify · Todos los derechos reservados</p>
          <div className='footer__middle-rrss'>
            <p>Contacto y redes sociales:</p>
            <ul>
              <li>
                <a
                  href='https://www.instagram.com/cheffify_cl/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src={IconIns} />
                </a>
              </li>
              <li>
                <a href='mailto:cheffify@gmail.com'>
                  <img src={IconMail} />
                </a>
              </li>
              <li>
                <a href='tel:+56944732293'>
                  <img src={IconTel} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='footer__bottom'>
          <p className='footer__bottom-desktop'>
            <strong>Política de privacidad</strong>: nuestro sitio web emplea la
            información con el fin de proporcionar el mejor servicio posible,
            particularmente para mantener un registro de usuarios, de pedidos en
            caso que aplique, y mejorar nuestros productos y servicios. Es
            posible que sean enviados correos electrónicos periódicamente a
            través de nuestro sitio con ofertas especiales, nuevos productos y
            otra información publicitaria que consideremos relevante para usted
            o que pueda brindarle algún beneficio, estos correos electrónicos
            serán enviados a la dirección que usted proporcione y podrán ser
            cancelados en cualquier momento.{' '}
            <a href={FilePDF} target='_blank' rel='noreferrer'>
              Seguir leyendo +
            </a>
          </p>
          <p className='footer__bottom-mobile'>
            Política de privacidad.{' '}
            <a href={FilePDF} target='_blank' rel='noreferrer'>
              Leer aquí
            </a>
          </p>
        </div>
      </div>
      <ToastContainer />
      <BtnScrollUp />
    </FooterBox>
  )
}

export default Footer
