import { useState } from 'react'
// Hooks
import { useNotify } from '../../../hooks/useToastNotification'
// Uitls
import ProfileCover from '../../../assets/images/svg/img/persona2.svg'
import IconEdit from '../../../assets/images/svg/icons/icon-edit.svg'
// Styles
import ContainerInfo from './styles'

const InformationPersonal = () => {
  const dataUser = JSON.parse(sessionStorage.getItem('dataUser'))
  // States
  const [phone, setPhone] = useState(dataUser.celular)

  const handleClickEditPhone = () => {
    const id = dataUser.id
    fetch(
      `https://cheffify.cl/administrator/login/update_profile.php?id=${id}&phone=${phone}`
    )
      // Exito
      .then(response => response.json()) // convertir a json
      .then(result => {
        if (result.status === 1) {
          const data = JSON.parse(sessionStorage.getItem('dataUser'))
          data.celular = phone
          sessionStorage.setItem('dataUser', JSON.stringify(data))
          useNotify({
            message: 'Información actualizada',
            type: 'success',
            close: 2000,
          })
        }
        if (result.status === 0) {
          useNotify({
            message: 'Error de servidor al actualizar datos',
            type: 'error',
            close: 2000,
          })
        }
      })
      .catch(err => console.log('Solicitud fallida', err))
  }

  return (
    <ContainerInfo>
      <img src={ProfileCover} className='profileImage' />
      <div className='blockInfo'>
        <div className='blockInfo__container'>
          <p className='blockInfo__container-name'>{dataUser.nombre}</p>
        </div>
        <div className='blockInfo__container'>
          <p className='blockInfo__container-txt'>{dataUser.email}</p>
        </div>
        <div className='blockInfo__container'>
          <input
            className='blockInfo__container-inputInfo'
            type='text'
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />

          <img
            src={IconEdit}
            className='blockInfo__container-icon'
            onClick={handleClickEditPhone}
          />
        </div>
      </div>
    </ContainerInfo>
  )
}

export default InformationPersonal
