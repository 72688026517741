import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// Redux
import { useDispatch } from 'react-redux'
import { addProduct } from '../../../../redux/productosSlice'
// Molecules
import ButtonAddProduct from '../../../molecules/ButtonAddProduct'
import ControlsAddOrRemove from '../../../molecules/ControlsAddOrRemove'
import ModalCustom from '../../../molecules/ModalCustom'
// Hooks
import { useNotify } from '../../../../hooks/useToastNotification'
// Docs
import InfoNutricionalPDF from '../../../../assets/docs/pdf/informacion_nutricional.pdf'
// Images
import IconFile from '../../../../assets/images/svg/icons/icon-file.svg'
// Utils
import { PRODUCTS } from '../../../../config/db.products'
import { filterProductBySlug } from '../../../../utils/functions'
import { formatPriceCLP } from '../../../../utils/formats'
// Styles
import Container from './styles'

const CardSingleProduct = ({ slugProduct }) => {
  const dispatch = useDispatch()
  const [productSelected, setProductSelected] = useState(null)
  const [quantity, setQuantity] = useState(1)
  const [isOpenModal, setIsOpenModal] = useState(false)

  useEffect(() => {
    setProductSelected(filterProductBySlug(PRODUCTS, slugProduct))
  }, [slugProduct])

  const handleClickAddProduct = () => {
    const params = {
      title: productSelected.title,
      description: productSelected.description,
      image: productSelected.image,
      price: productSelected.price,
      quantity,
    }
    dispatch(addProduct(params))
    useNotify({ message: 'Producto guardado!', type: 'success', close: 2000 })
  }

  return (
    productSelected && (
      <Container>
        <div className='boxLeft'>
          <img
            src={productSelected.image}
            alt={productSelected.title}
            className='boxLeft__image'
            onClick={() => setIsOpenModal(!isOpenModal)}
          />
        </div>
        <div className='boxRight'>
          <div className='boxRight__head'>
            <Link className='boxRight__head-btnBack' to='/shop'>
              Volver
            </Link>
            <a
              href={InfoNutricionalPDF}
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={IconFile} />
              Info Nutricional
            </a>
          </div>

          <div className='boxRight__nameAndCategory'>
            <h1 className='boxRight__nameAndCategory-title'>
              {productSelected.title}
            </h1>
            <span className='boxRight__nameAndCategory-category'>
              - {productSelected.category}
            </span>
          </div>
          <p className='boxRight__description'>
            {productSelected.descriptionLarge}
          </p>
          <span className='boxRight__price'>
            {formatPriceCLP(productSelected.price)}
          </span>
          <div className='boxRight__controls'>
            <ControlsAddOrRemove
              setQuantity={setQuantity}
              quantity={quantity}
            />
            <ButtonAddProduct onClick={handleClickAddProduct} />
          </div>
        </div>
        <ModalCustom
          isOpenModal={isOpenModal}
          onClose={setIsOpenModal}
          modalBody={<img src={productSelected.image} />}
        />
      </Container>
    )
  )
}

export default CardSingleProduct
