import { useEffect } from 'react'
import ShopTemplate from '../components/templates/Shop'

const Shop = () => {
  const URL_BACK = '/'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <ShopTemplate urlBack={URL_BACK} />
}

export default Shop
