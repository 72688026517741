import Modal from 'react-modal'
import InputForm from '../../molecules/InputForm'
import Button from '../../molecules/Button'
import ModalContainer from './styles'
// Utils
import { fetchGetAddress } from '../../../utils/api'
import { COLORS } from '../../../theme/css/global.colors'
import { useState } from 'react'
// Hooks
import { useNotify } from '../../../hooks/useToastNotification'
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '500px',
  },
}

const index = ({ isVisibleModal, setIsVisibleModal, setAddress }) => {
  const dataUser = JSON.parse(sessionStorage.getItem('dataUser'))
  const [nameAddress, setNameAddress] = useState('')
  const [addressInput, setAddressInput] = useState('')
  const [comuna, setComuna] = useState('')

  const handleClickSaveAddress = () => {
    fetch(
      `https://cheffify.cl/administrator/login/add_address.php?id_user=${dataUser.id}&name=${nameAddress}&address=${addressInput}&comuna=${comuna}`
    )
      // Exito
      .then(response => response.json()) // convertir a json
      .then(json => {
        if (json.status === 0) {
          useNotify({
            message: 'Dirección agregada.',
            type: 'success',
            close: 2000,
          })
          setIsVisibleModal(false)
          fetchGetAddress({ idUser: dataUser.id }).then(result =>
            setAddress(result)
          )
        }
        json.status === 1 &&
          useNotify({
            message: 'Error al agregar dirección, intente mas tarde.',
            type: 'error',
            close: 2000,
          })
      })
      .catch(err => console.log('Solicitud fallida', err))
  }

  return (
    <Modal isOpen={isVisibleModal} style={customStyles}>
      <ModalContainer className='containerModal'>
        <button
          className='containerModal__close'
          onClick={() => setIsVisibleModal(!isVisibleModal)}
        >
          X
        </button>
        <div className='containerModal__form'>
          <h1 className='containerModal__form-title'>Datos de dirección</h1>
          <InputForm
            type='text'
            name='nameAddress'
            id='nameAddress'
            placeholder='Nombre'
            onChangeInput={setNameAddress}
          />
          <InputForm
            type='text'
            name='address'
            id='address'
            placeholder='Dirección'
            onChangeInput={setAddressInput}
          />
          <InputForm
            type='text'
            name='comuna'
            id='comuna'
            placeholder='Comuna'
            onChangeInput={setComuna}
          />
          <Button
            text='Guardar dirección'
            fontSize={17}
            color={COLORS.white}
            bgColor={COLORS.salmon}
            borderColor={COLORS.salmon}
            borderRadius={6}
            onClick={handleClickSaveAddress}
          />
        </div>
      </ModalContainer>
    </Modal>
  )
}

export default index
