/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import Button from '../../molecules/Button'
import Title from '../../atoms/title'
// Hooks
import { useNotify } from '../../../hooks/useToastNotification'
import IconTrash from '../../../assets/images/svg/icons/icon-trash.svg'
import IconEdit from '../../../assets/images/svg/icons/icon-edit.svg'
// Utils
import { fetchGetAddress } from '../../../utils/api'
// Styles
import { COLORS } from '../../../theme/css/global.colors'
import { CardAddress } from './styles'

const Address = ({ setIsVisibleModal, address, setAddress }) => {
  const [nameEditInput, setNameEditInput] = useState('')
  const [addressEditInput, setAddressEditInput] = useState('')
  const [comunaEditInput, setComunaEditInput] = useState('')

  const dataUser = JSON.parse(sessionStorage.getItem('dataUser'))

  const handleClickEdit = (
    idAddress,
    defaultName,
    defaultAddress,
    defaultComuna
  ) => {
    fetch(
      `https://cheffify.cl/administrator/login/edit_address.php?id=${idAddress}&name=${
        nameEditInput === '' ? defaultName : nameEditInput
      }&address=${
        addressEditInput === '' ? defaultAddress : addressEditInput
      }&comuna=${comunaEditInput === '' ? defaultComuna : comunaEditInput}`
    )
      // Exito
      .then(response => response.json()) // convertir a json
      .then(result => {
        if (result.status === 0) {
          useNotify({
            message: 'Dirección editada!',
            type: 'success',
            close: 2000,
          })
          fetchGetAddress({ idUser: dataUser.id }).then(result =>
            setAddress(result)
          )
        }
        if (result.status === 1) {
          useNotify({
            message: 'Error al editar dirección, intente mas tarde.',
            type: 'error',
            close: 2000,
          })
        }
      })
      .catch(err => console.log('Solicitud fallida', err))
  }

  const handleSureDeleteAddress = idAddress => {
    const isBoss = confirm('¿Estás seguro de eliminar?')
    isBoss === true && handleClickDelete(idAddress)
  }

  const handleClickDelete = idAddress => {
    fetch(
      `https://cheffify.cl/administrator/login/delete_address.php?id=${idAddress}`
    )
      // Exito
      .then(response => response.json()) // convertir a json
      .then(result => {
        if (result.status === 0) {
          useNotify({
            message: 'Dirección eliminada!',
            type: 'success',
            close: 2000,
          })
          fetchGetAddress({ idUser: dataUser.id }).then(result =>
            setAddress(result)
          )
        }

        if (result.status === 1) {
          useNotify({
            message: 'Error al eliminar dirección, intente mas tarde.',
            type: 'error',
            close: 2000,
          })
        }
      })
      .catch(err => console.log('Solicitud fallida', err))
  }

  useEffect(() => {
    fetchGetAddress({ idUser: dataUser.id }).then(result => setAddress(result))
  }, [])

  return (
    <>
      <Button
        text='Agregar dirección'
        fontSize={14}
        color={COLORS.white}
        bgColor={COLORS.salmon}
        borderColor={COLORS.salmon}
        borderRadius={6}
        onClick={setIsVisibleModal}
        width={200}
        customStyle={{ display: 'block', margin: '0 auto', marginBottom: 30 }}
      />
      {address.length > 0 ? (
        <CardAddress>
          {address &&
            address.map(item => (
              <div className='card' key={item.id}>
                <div className='card__btns'>
                  <img
                    src={IconEdit}
                    onClick={() =>
                      handleClickEdit(
                        item.id,
                        item.name,
                        item.address,
                        item.comuna
                      )
                    }
                  />
                  <img
                    src={IconTrash}
                    onClick={() => handleSureDeleteAddress(item.id)}
                  />
                </div>
                <div className='card__info'>
                  <input
                    type='text'
                    className='card__info-title'
                    defaultValue={item.name}
                    onChange={e => setNameEditInput(e.target.value)}
                  />
                  <input
                    type='text'
                    className='card__info-text'
                    defaultValue={item.address}
                    onChange={e => setAddressEditInput(e.target.value)}
                  />
                  <input
                    type='text'
                    className='card__info-text'
                    defaultValue={item.comuna}
                    onChange={e => setComunaEditInput(e.target.value)}
                  />
                </div>
              </div>
            ))}
        </CardAddress>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Title
            title='No se han registrado direcciones'
            fontSize={18}
            color='#D77466'
          />
        </div>
      )}
    </>
  )
}

export default Address
