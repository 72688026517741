import styled from 'styled-components'
import { COLORS } from '../../../theme/css/global.colors'

const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${COLORS.salmon};
  width: 100%;
  max-width: 70px;
  .quantityNumber {
    padding: 10px 20px 10px 20px;
    border-right: 1px solid ${COLORS.salmon};
    color: ${COLORS.salmon};
    font-size: 17px;
    font-weight: bold;
  }
  .controlBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__btn {
      background-color: transparent;
      border: none;
      color: ${COLORS.salmon};
      cursor: pointer;
    }
  }
`
export default Container
