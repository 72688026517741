import { Link, useNavigate } from 'react-router-dom'
// Redux
import { useDispatch } from 'react-redux'
import { showOrHideCesta } from '../../../redux/productosSlice'
// Organism
import Cesta from '../../organisms/cesta'
// Molecules
import BtnOrderFood from '../../molecules/BtnOrderFood'
import BtnAccount from '../../molecules/BtnAccount'
import { COLORS } from '../../../theme/css/global.colors'
// Svg
import Logo from '../../../assets/images/svg/logos/cheffify.svg'
import MenuSvg from '../../../assets/images/svg/img/menu-bg.svg'
import IconLogOut from '../../../assets/images/svg/icons/icon-logout.svg'
// Styles
import Menu from './styles'

const MenuLogin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleClickLogOut = () => {
    sessionStorage.removeItem('dataUser')
    navigate('/')
  }

  return (
    <Menu>
      <div className={`${scroll >= 170 && 'fixedMenu'}`}>
        <div className='menu'>
          <div className='menu__elementsAbsolute'>
            <img
              src={MenuSvg}
              className={`menu__elementsAbsolute-img ${
                scroll >= 170 && 'display-none'
              }`}
              alt='Elemento absolute del menu'
            />
          </div>
          <Link to='/'>
            <img src={Logo} className='menu__logo' alt='Logo Cheffify' />
          </Link>
          <div className='menu__btns'>
            <BtnOrderFood
              text='Pedir comida'
              bgColor={COLORS.salmon}
              icon='bell'
              iconColor={COLORS.white}
              sizeIcon={15}
            />
            <BtnAccount
              text='Mi cuenta'
              bgColor={COLORS.violetDark}
              iconLeft='account'
              iconRight='cart'
              iconColor={COLORS.white}
              sizeIcon={15}
              onClickCesta={() => dispatch(showOrHideCesta())}
            />
            <img
              src={IconLogOut}
              alt='Icono para cerrar sesión'
              className='iconLogOut'
              onClick={handleClickLogOut}
            />
          </div>
        </div>
        <Cesta />
      </div>
    </Menu>
  )
}

export default MenuLogin
