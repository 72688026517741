import styled from 'styled-components'
// Utils
import { COLORS } from '../../../theme/css/global.colors'

const Menu = styled.div`
  .fixedMenu {
    position: fixed;
    z-index: 2;
    width: 100%;
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 170px 30px 190px;
    background-color: ${COLORS.white};
    @media (max-width: 1160px) {
      padding: 40px 30px 30px 100px;
    }
    @media (max-width: 991px) {
      padding: 11px 25px 11px 25px;
      justify-content: none;
    }
    &__logo {
      width: 100%;
      max-width: 116px;
    }
    &__links {
      display: flex;
      @media (max-width: 991px) {
        display: none;
      }
      &-contain {
        display: flex;
        align-items: center;
        margin-left: 45px;
        cursor: pointer;
        &--txt {
          color: ${COLORS.violetDark};
          font-size: 14px;
          padding-left: 6px;
        }
      }
    }
    &__btns {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      &-btnBack {
        background-color: ${COLORS.lightGray};
        color: ${COLORS.grey};
        padding: 10px 15px;
        border-radius: 25px;
        transition: color ease-in-out 1s;
        margin-right: 10px;
        text-align: center;
        font-size: 14px;
        &:hover {
          color: ${COLORS.salmon};
        }
      }
      .iconLogOut {
        height: 32px;
        margin-left: 15px;
        cursor: pointer;
      }
    }
    // Elements Absolute
    &__elementsAbsolute {
      position: absolute;
      left: 0;
      @media (max-width: 991px) {
        display: none;
      }
      &-img {
        width: 88px;
        height: 167px;
      }
    }
  }
`
export default Menu
