import styled from 'styled-components'
// Utils
import { COLORS } from '../../../../theme/css/global.colors'
import { device } from '../../../../theme/css/media.querys'

const Container = styled.div`
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #cb6d6166;
  border-radius: 15px;
  opacity: 1;
  padding: 24px;
  max-width: 1080px;
  justify-content: center;
  margin: 0 auto;
  @media ${device.ipad} {
    flex-direction: column;
  }
  .boxLeft {
    margin-right: 40px;
    @media ${device.ipad} {
      margin: 0 auto;
      padding-bottom: 20px;
    }
    &__image {
      display: block;
      width: 100%;
      max-width: 360px;
      border-radius: 5px;
      cursor: pointer;
      @media ${device.ipad} {
        max-width: 100%;
      }
    }
  }
  .boxRight {
    max-width: 680px;
    &__head {
      display: flex;
      justify-content: space-between;
      &-btnBack {
        background-color: ${COLORS.lightGray};
        color: ${COLORS.grey};
        padding: 10px 15px;
        border-radius: 5px;
        transition: color ease-in-out 1s;
        &:hover {
          color: ${COLORS.salmon};
        }
      }
      a {
        display: flex;
        align-items: center;
        background-color: ${COLORS.lightGray};
        color: ${COLORS.grey};
        padding: 10px 15px;
        border-radius: 5px;
        transition: color ease-in-out 1s;
        &:hover {
          color: ${COLORS.salmon};
        }
        img {
          width: 15px;
          padding-right: 10px;
        }
      }
    }
    &__nameAndCategory {
      display: flex;
      margin-top: 40px;
      align-items: center;
      @media ${device.ipad} {
        flex-direction: column;
      }
      &-title {
        font-size: 22px;
        color: ${COLORS.violetDark};
        @media ${device.ipad} {
          font-size: 18px;
        }
      }
      &-category {
        font-size: 22px;
        color: ${COLORS.salmon};
        font-weight: lighter;
        margin-left: 10px;
        @media ${device.ipad} {
          font-size: 18px;
        }
      }
    }
    &__description {
      color: ${COLORS.gris};
      font-size: 17px;
      margin-bottom: 25px;
      margin-top: 20px;
      @media ${device.ipad} {
        font-size: 15px;
      }
    }
    &__price {
      display: block;
      color: ${COLORS.violetDark};
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 16px;
      @media ${device.ipad} {
        font-size: 16px;
      }
    }
    &__controls {
      display: flex;
    }
  }
`
export default Container
