import Navbar from '../../organisms/menu'
import Card404 from '../../organisms/404/Card404'
import Footer from '../../organisms/footer'

const Template404 = () => {
  return (
    <>
      <Navbar />
      <div className='wrapper'>
        <Card404 />
      </div>
      <Footer />
    </>
  )
}

export default Template404
