import { useNavigate } from 'react-router-dom'
// Molecules
import Button from '../../molecules/Button'
// Utils
import { COLORS } from '../../../theme/css/global.colors'
// Svgs
import bgLeft from '../../../assets/images/svg/img/bg-five-left.svg'
import bgRight from '../../../assets/images/svg/img/bg-five-right.svg'
import ImgLeft from '../../../assets/images/svg/img/404-left.svg'
import ImgRigth from '../../../assets/images/svg/img/404-right.svg'
import Wrapper from './styles'

const Card404 = () => {
  const navigate = useNavigate()
  const handleClickBack = () => navigate('/')

  return (
    <Wrapper>
      <div className='card'>
        <h1 className='card__title'>404</h1>
        <h2 className='card__subtitle'>¡OPS!</h2>
        <p className='card__description'>
          Lo sentimos, esta página no está disponible.
        </p>
        <img src={ImgLeft} className='card__imgLeft' />
        <img src={ImgRigth} className='card__imgRight' />
      </div>
      <Button
        text='Volver'
        bgColor={COLORS.salmon}
        color={COLORS.white}
        fontSize={17}
        width={195}
        customStyle={{ marginTop: 40 }}
        onClick={handleClickBack}
      />
      <img src={bgLeft} className='bgLeft' />
      <img src={bgRight} className='bgRight' />
    </Wrapper>
  )
}

export default Card404
