import { useEffect } from 'react'
import CongeladosTemplate from '../components/templates/Congelados'

const CongeladosPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return <CongeladosTemplate />
}

export default CongeladosPage
