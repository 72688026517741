import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Home from '../pages/Home'
import Shop from '../pages/Shop'
import Delivery from '../pages/Delivery'
import Profile from '../pages/Profile'
import Login from '../pages/Login'
import CreateAccount from '../pages/CreateAccount'
import BuySuccess from '../pages/BuySuccess'
import BuyFailed from '../pages/BuyFailed'
import Page404 from '../pages/404'
import SingleProductPage from '../pages/SingleProduct'
import CongeladosPage from '../pages/Congelados'

const RoutesNav = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home urlNav={true} />} />
        <Route path='/shop' element={<Shop />} />
        <Route path='/shop/:productSlug' element={<SingleProductPage />} />
        <Route path='/congelados' element={<CongeladosPage />} />
        <Route path='/delivery' element={<Delivery />} />
        <Route path='/perfil' element={<Profile />} />
        <Route path='/login' element={<Login />} />
        <Route path='/crear-cuenta' element={<CreateAccount />} />
        <Route path='/success' element={<BuySuccess />} />
        <Route path='/failed' element={<BuyFailed />} />
        <Route path='/404' element={<Page404 />} />
        <Route path='*' element={<Navigate to='/404' />} />
      </Routes>
    </BrowserRouter>
  )
}

export default RoutesNav
