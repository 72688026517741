import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ProfileTemplate from '../components/templates/Profile'

const ProfilePage = () => {
  const navigate = useNavigate()
  const dataUser = sessionStorage.getItem('dataUser')

  const [isVisibleModal, setIsVisibleModal] = useState(false)
  const [address, setAddress] = useState([])
  useEffect(() => {
    if (dataUser === null) {
      navigate('/')
    }
  }, [])

  return (
    dataUser && (
      <ProfileTemplate
        {...{ isVisibleModal, setIsVisibleModal, address, setAddress }}
      />
    )
  )
}

export default ProfilePage
