import Navbar from '../../organisms/menu'
import Banner from '../../organisms/congelados/Banner'
import Ventajas from '../../organisms/congelados/Ventajas'
import Preparacion from '../../organisms/congelados/Preparacion'
import Footer from '../../organisms/footer'

const CongeladosTemplate = () => {
  return (
    <>
      <Navbar />
      <div className='wrapper'>
        <Banner />
      </div>
      <Ventajas />
      <Preparacion />
      <Footer />
    </>
  )
}

export default CongeladosTemplate
