// Styles
import Title from './styles'

const TitleSection = ({
  textFirst,
  colorTextFirst,
  textSecond,
  fontSize,
  lineBreak,
  textAlign,
  className,
  type = 'subtitle',
}) => {
  return (
    <Title
      fontSize={fontSize}
      colorTextFirst={colorTextFirst}
      textAlign={textAlign}
      className={className && className}
    >
      {type === 'title' && (
        <h1 className='text'>
          {textFirst} {lineBreak === true && <br />} <span>{textSecond}</span>
        </h1>
      )}
      {type === 'subtitle' && (
        <h2 className='text'>
          {textFirst} {lineBreak === true && <br />} <span>{textSecond}</span>
        </h2>
      )}
      {type === 'h4' && (
        <h4 className='text'>
          {textFirst} {lineBreak === true && <br />} <span>{textSecond}</span>
        </h4>
      )}
    </Title>
  )
}

export default TitleSection
