import styled from 'styled-components'
import { COLORS } from '../../../theme/css/global.colors'
// import { device } from '../../.../../../theme/css/media.querys'

const ModalContainer = styled.div`
  .containerModal {
    &__close {
      background-color: transparent;
      border: none;
      font-family: 'Raleway';
      font-weight: bold;
      color: ${COLORS.salmon};
      text-align: end;
      cursor: pointer;
      width: 100%;
    }
    &__form {
      display: flex;
      flex-direction: column;
      &-title {
        font-family: 'Raleway';
        font-size: 18px;
        color: ${COLORS.violetDark};
        margin-bottom: 20px;
      }
    }
  }
`
export default ModalContainer
