import MetaTagsShop from './MetaTags'
import Navbar from '../../organisms/menu'
import CardSingleProduct from '../../organisms/products/CardSingleProduct'
import SecondSection from '../../organisms/home/SecondSection'
import Somos from '../../organisms/products/somos'
import Footer from '../../organisms/footer'
const SingleProductTemplate = ({ slugProduct, productSelected, urlBack }) => {
  return (
    <>
      <MetaTagsShop
        title={`${productSelected.title} | Platos Congelados Cheffify`}
        description={productSelected.metaDescription}
      />
      <Navbar {...{ urlBack }} />
      <div className='wrapper'>
        <CardSingleProduct {...{ slugProduct }} />
      </div>
      <SecondSection />
      <div className='wrapper'>
        <Somos />
      </div>
      <Footer />
    </>
  )
}

export default SingleProductTemplate
