import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// Redux
import { useDispatch } from 'react-redux'
import { showOrHideCesta } from '../../../redux/productosSlice'
// Organism
import Cesta from '../../organisms/cesta'
// Molecules
import BtnOrderFood from '../../molecules/BtnOrderFood'
import BtnAccount from '../../molecules/BtnAccount'
// Icons
import Icon from 'cheffify-react-icons'
// Hooks
import { useNotify } from '../../../hooks/useToastNotification'
// Utils
import { URL_NAV } from '../../../utils/const'
import { COLORS } from '../../../theme/css/global.colors'
// Svg
import Logo from '../../../assets/images/svg/logos/cheffify.svg'
import MenuSvg from '../../../assets/images/svg/img/menu-bg.svg'
import IconLogOut from '../../../assets/images/svg/icons/icon-logout.svg'
// Styles
import Menu from './styles'

const Navbar = ({ urlNav, urlBack }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [scroll, setScroll] = useState(0)
  const dataUser = sessionStorage.getItem('dataUser')

  const handleClickScroll = sectionId => {
    const element = document.getElementById(sectionId)
    element.scrollIntoView({ behavior: 'smooth' })
  }

  const handleScroll = () => {
    const scrollPosition = window.scrollY // => scroll position
    setScroll(scrollPosition)
  }

  const handleClickLogOut = () => {
    sessionStorage.removeItem('dataUser')
    navigate('/')
    useNotify({
      message: 'Sesión cerrada',
      type: 'success',
      close: 2000,
    })
  }

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Menu>
      <div className={`${scroll >= 170 && 'fixedMenu'}`}>
        <div className='menu'>
          <div className='menu__elementsAbsolute'>
            <img
              src={MenuSvg}
              className={`menu__elementsAbsolute-img ${
                scroll >= 170 && 'display-none'
              }`}
              alt='Elemento absolute del menu'
            />
          </div>
          <Link to='/'>
            <img src={Logo} className='menu__logo' alt='Logo Cheffify' />
          </Link>

          <div className='menu__links'>
            {urlNav &&
              URL_NAV.map((item, index) => (
                <Link
                  to={item.url}
                  className='menu__links-contain'
                  key={index}
                  onClick={() =>
                    item.url === '' && handleClickScroll(item.menuAncla)
                  }
                >
                  <Icon
                    name={item.icon.name}
                    size={item.icon.size}
                    color={COLORS.violetDark}
                  />
                  <p className='menu__links-contain--txt'>{item.name}</p>
                </Link>
              ))}
          </div>
          <div className='menu__btns'>
            {!urlNav && (
              <Link
                to={!urlBack ? '/' : urlBack}
                className='menu__btns-btnBack'
              >
                Volver
              </Link>
            )}

            <BtnOrderFood
              text='Pedir comida'
              bgColor={COLORS.salmon}
              icon='bell'
              iconColor={COLORS.white}
              sizeIcon={15}
            />
            <BtnAccount
              text='Mi cuenta'
              bgColor={COLORS.violetDark}
              iconLeft='account'
              iconRight='cart'
              iconColor={COLORS.white}
              sizeIcon={15}
              onClickCesta={() => dispatch(showOrHideCesta())}
            />
            {dataUser !== null && (
              <img
                src={IconLogOut}
                alt='Icono para cerrar sesión'
                className='iconLogOut'
                onClick={handleClickLogOut}
              />
            )}
          </div>
        </div>
        <Cesta />
      </div>
    </Menu>
  )
}

export default Navbar
