import styled from 'styled-components'
// Utils
import { device } from '../../../theme/css/media.querys'
import { COLORS } from '../../../theme/css/global.colors'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 16px;
  background-color: ${props => props.bgColor};
  cursor: pointer;
  justify-content: center;
  height: 32px;
  transition: opacity ease-in-out 0.3s;
  overflow: hidden;
  @media (max-width: 991px) {
    background-color: transparent;
    padding: 8px 0px 8px 0px;
    height: 20px;
    overflow: visible;
  }
  &:hover {
    opacity: 0.9;
  }
  .text {
    font-size: 12px;
    color: ${COLORS.white};
    padding-left: 10px;
    @media (max-width: 991px) {
      display: none;
    }
  }
  .separator {
    position: relative;
    height: 32px;
    width: 1px;
    background-color: ${COLORS.white};
    @media (max-width: 991px) {
      display: none;
    }
  }
  .boxIcon {
    padding: 16px 16px 16px 16px;
    @media ${device.ipad} {
      padding: 0px;
    }
    .icon {
      @media (max-width: 991px) {
        background-color: ${props => props.bgColor};
        padding: 8px;
        border-radius: 50%;
        margin-left: 8px;
      }
    }
  }

  .notification {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${COLORS.white};
    background-color: ${COLORS.salmon};
    border-radius: 50%;
    width: 12px;
    height: 12px;
    font-size: 10px;
    padding: 0px;
    z-index: 9;
    line-height: 0px;
    top: 5px;
    right: 7px;
    @media ${device.ipad} {
      right: -5px;
      top: 0px;
      padding: 2px;
    }
  }

  .link {
    display: flex;
    align-items: center;
    padding: 16px 16px 16px 16px;
    @media ${device.ipad} {
      padding: 8px;
      background-color: ${props => props.bgColor};
      border-radius: 50%;
      margin-left: 10px;
    }
  }
`
export default Wrapper
