import TitleSection from '../../../molecules/TitleSection'
// Images
import Person1 from '../../../../assets/images/svg/img/persona1.svg'
import Person2 from '../../../../assets/images/svg/img/persona2.svg'
import Person3 from '../../../../assets/images/svg/img/persona3.svg'
// Styles
import Wrapper from './styles'

const FeedBack = () => {
  return (
    <Wrapper id='feedback'>
      <div className='boxFeedback'>
        <TitleSection
          textFirst='Guatita llena, '
          textSecond='corazón contento.'
        />
        <p className='boxFeedback__description'>
          Porque sabemos lo importante de tener referencias, no hay mejor aval
          que la opinión de nuestros clientes. Aquí verás algunos de sus
          comentarios.
        </p>
        <div className='cardContainer'>
          <div className='cardContainer__card'>
            <img src={Person2} className='cardContainer__card-image' />
            <h5 className='cardContainer__card-title'>Moisés Andrade</h5>
            <p className='cardContainer__card-text'>
              Los sabores de Cheffify me transportaron a un momento mágico de la
              infancia, donde saboreaba la cocina de mi madre y mis abuelas. Es
              sorprendete cómo sus platos logran ese efecto.
            </p>
          </div>
          <div className='cardContainer__card'>
            <img src={Person1} className='cardContainer__card-image' />
            <h5 className='cardContainer__card-title'>Maida Larraín </h5>
            <p className='cardContainer__card-text'>
              Cheffify me soluciona el día a día de las comidas de mis hijos
              para el colegio. Rápido, económico y variado. Nunca repetimos
              plato. Sé que la salud de mi familia está en buenas manos.
            </p>
          </div>
          <div className='cardContainer__card'>
            <img src={Person3} className='cardContainer__card-image' />
            <h5 className='cardContainer__card-title'>Adriana Bustamante</h5>
            <p className='cardContainer__card-text'>
              Algo que no tiene precio es llegar a mi departamento despues de un
              lago día de trabajo y no tener que pensar en qué cocinar. Soy
              buena en la cocina, pero eso lo dejo para los fines de semana
              junto a mi pololo y amigos.
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default FeedBack
