import Modal from 'react-modal'
import ModalContainer from './styles'
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'initial',
    maxWidth: '600px',
  },
  overlay: {
    backgroundColor: '#000000ad',
    zIndex: 9,
  },
}
const ModalCustom = ({ isOpenModal, modalBody, onClose }) => {
  return (
    <Modal isOpen={isOpenModal} style={customStyles}>
      <ModalContainer className='modal'>
        <div className='modal__close'>
          <button onClick={() => onClose(!isOpenModal)}>X</button>
        </div>
        <div className='modal__body'>{modalBody}</div>
      </ModalContainer>
    </Modal>
  )
}

export default ModalCustom
