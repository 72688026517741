import styled from 'styled-components'
import { device } from '../../.../../../theme/css/media.querys'

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  @media ${device.ipad} {
    flex-direction: column;
  }
`
export default Container
