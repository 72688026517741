import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// Redux
import { useDispatch } from 'react-redux'
import { hideCesta } from '../redux/productosSlice'
// Templates
import SingleProductTemplate from '../components/templates/Shop/SingleProduct'
// Utils
import { PRODUCTS } from '../config/db.products'
import { filterProductBySlug } from '../utils/functions'

const SingleProductPage = () => {
  // 👇️ get Slug Product from url
  const params = useParams()
  const dispatch = useDispatch()

  const URL_BACK = '/shop'

  const [productSelected, setProductSelected] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(hideCesta())
    setProductSelected(filterProductBySlug(PRODUCTS, params.productSlug))
  }, [])
  return (
    productSelected && (
      <SingleProductTemplate
        slugProduct={params.productSlug}
        productSelected={productSelected}
        urlBack={URL_BACK}
      />
    )
  )
}

export default SingleProductPage
