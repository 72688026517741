export const COLORS = {
  black: '#000',
  white: '#FFF',
  turqoiseDark: '#313151',
  salmon: '#D77466',
  salmonLight: '#F5D4C3',
  violetDark: '#262C57',
  beige: '#EFE7CE',
  gris: '#C2C2CE',
  grey: '#80808B',
  lightGray: '#EDEDED',
  softGray: '#BEBEC9',
  lightGrayBorderProduct: '#E8E8EF',
  violetLight: '#646477',
  aqua: '#275C73',
  roseLight: '#FCF3F2',
  shadowSalmon: '#d7746666',
  salmonText: '#DA7466',
}
