import { useEffect } from 'react'
// Redux
import { useDispatch } from 'react-redux'
import { hideCesta } from '../redux/productosSlice'
import HomeTemplate from '../components/templates/Home'

const HomePage = ({ urlNav }) => {
  const RANDOM_PRODUCTS_HOME = true

  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(hideCesta())
  }, [])

  return <HomeTemplate {...{ urlNav, randomProducts: RANDOM_PRODUCTS_HOME }} />
}

export default HomePage
