import TitleSection from '../../../molecules/TitleSection'
// Images
import LaTercera from '../../../../assets/images/svg/prensa/latercera.svg'
import Ed from '../../../../assets/images/svg/prensa/ed.svg'
import Elservirsen from '../../../../assets/images/svg/prensa/elservirsen.svg'
import MesaMantel from '../../../../assets/images/svg/prensa/mesamantel.svg'
// Docs
import EdFile from '../../../../assets/docs/pdf/revista-ed.pdf'
import ElServirsenFile from '../../../../assets/docs/pdf/elservisen.pdf'
import MesaMantelFile from '../../../../assets/docs/pdf/mesa-mantel.pdf'
// Styles
import Wrapper from './styles'

const Prensa = () => {
  return (
    <Wrapper>
      <TitleSection
        textFirst='Ademas de dar de comer,'
        textSecond='damos de qué hablar.'
        lineBreak={true}
        textAlign='center'
      />
      <div className='container'>
        <a
          href='https://finde.latercera.com/comer/mjb-a-tu-mesa-delivery-cocina-rica-sano/'
          target='_blank'
          rel='noopener noreferrer'
          className='container__link'
        >
          <img src={LaTercera} className='container__link-image' />
        </a>
        <a
          href={EdFile}
          target='_blank'
          rel='noopener noreferrer'
          className='container__link'
        >
          <img src={Ed} className='container__link-image' />
        </a>
        <a
          href={ElServirsenFile}
          target='_blank'
          rel='noopener noreferrer'
          className='container__link'
        >
          <img src={Elservirsen} className='container__link-image' />
        </a>
        <a
          href={MesaMantelFile}
          target='_blank'
          rel='noopener noreferrer'
          className='container__link'
        >
          <img src={MesaMantel} className='container__link-image' />
        </a>
      </div>
    </Wrapper>
  )
}

export default Prensa
