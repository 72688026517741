// Styles
import Select from './styles'

const SelectForm = ({ onChangeSelect, error }) => {
  return (
    <Select
      onChange={e => onChangeSelect && onChangeSelect(e.target.value)}
      error={error}
    >
      <option value=''>Seleccione comuna</option>
      <option value='Cerro Navia'>Cerro Navia</option>
      <option value='Conchalí'>Conchalí</option>
      <option value='El Bosque'>El Bosque</option>
      <option value='Estación Central'>Estación Central</option>
      <option value='Huechuraba'>Huechuraba</option>
      <option value='Independencia'>Independencia</option>
      <option value='La Cisterna'>La Cisterna</option>
      <option value='La Florida'>La Florida</option>
      <option value='La Granja'>La Granja</option>
      <option value='La Pintana'>La Pintana</option>
      <option value='La Reina'>La Reina</option>
      <option value='Las Condes'>Las Condes</option>
      <option value='Lo Barnechea'>Lo Barnechea</option>
      <option value='Lo Espejo'>Lo Espejo</option>
      <option value='Lo Prado'>Lo Prado</option>
      <option value='Macul'>Macul</option>
      <option value='Maipú'>Maipú</option>
      <option value='Ñuñoa'>Ñuñoa</option>
      <option value='Padre Hurtado'>Padre Hurtado</option>
      <option value='Pedro Aguirre Cerda'>Pedro Aguirre Cerda</option>
      <option value='Peñalolén'>Peñalolén</option>
      <option value='Pirque'>Pirque</option>
      <option value='Providencia'>Providencia</option>
      <option value='Pudahuel'>Pudahuel</option>
      <option value='Puente Alto'>Puente Alto</option>
      <option value='Quilicura'>Quilicura</option>
      <option value='Quinta Normal'>Quinta Normal</option>
      <option value='Recoleta'>Recoleta</option>
      <option value='Renca'>Renca</option>
      <option value='San Bernardo'>San Bernardo</option>
      <option value='San Joaquín'>San Joaquín</option>
      <option value='San José de Maipo'>San José de Maipo</option>
      <option value='San Miguel'>San Miguel</option>
      <option value='San Ramón'>San Ramón</option>
      <option value='Santiago'>Santiago</option>
    </Select>
  )
}

export default SelectForm
