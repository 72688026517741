// Molecules
import ArrowScrollDown from '../../molecules/ArrowScrollDown'
import TitleSection from '../../molecules/TitleSection'
// Images
import bgLeft from '../../../assets/images/svg/img/bg-secondSection-left.svg'
import bgRight from '../../../assets/images/svg/img/bg-secondSection-right.svg'
import ImagenVentajas from '../../../assets/images/png/congelados/congelado.png'
// Utils
import { COLORS } from '../../../theme/css/global.colors'
import { VentajasDiv } from './styles.js'

const Ventajas = () => {
  return (
    <VentajasDiv id='sectionVentajas'>
      <ArrowScrollDown
        bgColor={COLORS.roseLight}
        color={COLORS.salmon}
        sectionId='sectionVentajas'
      />
      <TitleSection
        textFirst='Ventajas de los '
        textSecond='platos congelados'
        textAlign='center'
      />
      <div className='content'>
        <div className='content__image'>
          <img
            src={ImagenVentajas}
            alt='Como se conserva la comida congelada'
          />
        </div>
        <div className='content__information'>
          <ul className='content__information-list'>
            <li>
              <strong>Comodidad y conveniencia:</strong>
              Los platos congelados son prácticos para personas con poco tiempo
              para cocinar.
            </li>
            <li>
              <strong>Conservación de nutrientes:</strong>
              Al congelar los alimentos rápidamente, se preservan los nutrientes
              esenciales.
            </li>
            <li>
              <strong>Reducción del desperdicio de alimentos:</strong>
              Los platos congelados ayudan a evitar que los alimentos se echen a
              perder y se desperdicien.
            </li>
            <li>
              <strong>Amplia variedad:</strong>
              Existe una gran variedad de platos congelados disponibles para
              satisfacer diversos gustos y necesidades.
            </li>
          </ul>
        </div>
      </div>
      <img src={bgLeft} className='bgLeft' />
      <img src={bgRight} className='bgRight' />
    </VentajasDiv>
  )
}

export default Ventajas
