import Navbar from '../../organisms/menu'
import Cesta from '../../organisms/cesta'
import Footer from '../../organisms/footer'
import LoginForm from '../../organisms/LoginForm'

const LoginTemplate = () => {
  return (
    <>
      <Navbar />
      <Cesta />
      <div className='wrapper'>
        <LoginForm />
      </div>
      <Footer />
    </>
  )
}

export default LoginTemplate
