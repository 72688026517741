// SVGS
import IconPedido from '../../../../assets/images/svg/icons/icon-pedido.svg'
import IconOrden from '../../../../assets/images/svg/icons/icon-orden.svg'
import IconDelivery from '../../../../assets/images/svg/icons/icon-delivery.svg'
import IconEntrega from '../../../../assets/images/svg/icons/icon-recibido.svg'
import Container from './styles'

const ComoFunciona = () => {
  return (
    <Container>
      <h4 className='title'>Cómo funciona</h4>
      <div className='container'>
        <div className='container__block'>
          <img src={IconPedido} className='container__block-icon iconFirst' />
          <h5 className='container__block-title'>Haz tu pedido</h5>
          <p className='container__block-description'>
            Elige tus platos y añádelos al carrito de compras, luego elige la
            opción de pago que te acomode y paga tu pedido. No hay un valor
            mínimo de compra.
          </p>
        </div>
        <div className='container__block'>
          <img src={IconOrden} className='container__block-icon iconSecond' />
          <h5 className='container__block-title'>Recibimos tu orden</h5>
          <p className='container__block-description'>
            Una vez confirmado tu pedido, recibimos la orden y comenzamos con la
            preparación de tus platos, los cuales se envían congelados.
          </p>
        </div>
        <div className='container__block'>
          <img src={IconDelivery} className='container__block-icon iconThird' />
          <h5 className='container__block-title'>Enviamos tu compra</h5>
          <p className='container__block-description'>
            El envío se hará 24 horas después de haber realizado la compra. Los
            despachos se realizan de lunes a viernes (solo días hábiles) de 9:00
            a 16:30 horas. Valor del envío $4.000 para todas las comunas (solo
            Región Metropolitana).
          </p>
        </div>
        <div className='container__block'>
          <img src={IconEntrega} className='container__block-icon iconFour' />
          <h5 className='container__block-title'>Recibe tu pedido</h5>
          <p className='container__block-description'>
            Una vez que has recibido tus platos, solo queda descongelarlos para
            disfrutar. Puedes calentar tu comida desde un horno o microondas.
          </p>
        </div>
      </div>
    </Container>
  )
}

export default ComoFunciona
