import MenuLogin from '../../organisms/menu/menuLogin'
import Cesta from '../../organisms/cesta'
import InformationPersonal from '../../organisms/profile/InformationPersonal'
import Orders from '../../organisms/profile/Orders'
import Modal from '../../molecules/Modal'
import Footer from '../../organisms/footer'

const ProfileTemplate = ({
  isVisibleModal,
  setIsVisibleModal,
  address,
  setAddress,
}) => {
  return (
    <>
      <MenuLogin />
      <Cesta />
      <div className='wrapper'>
        <InformationPersonal />
        <Orders {...{ setIsVisibleModal, address, setAddress }} />
      </div>
      <Modal {...{ isVisibleModal, setIsVisibleModal, setAddress }} />
      <Footer />
    </>
  )
}

export default ProfileTemplate
