import { Helmet } from 'react-helmet-async'

const MetaTagsShop = () => {
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>Comida congelada a domicilio | Platos Congelados Cheffify</title>
      <meta
        name='description'
        content='Platos saludables y deliciosos, preparados y
congelados para tu comodidad. ¡Disfruta de comida casera sin el
trabajo de cocinar!'
      />
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Tomaticán",
          "image": "https://www.cheffify.cl/static/media/tomatican.81b4c97e0fb90838f729.jpg",
          "description": "Carne mechada con tomate fresco y choclo, por supuesto que añadimos un toque de nuestro recetario familiar, maravilloso",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "3950"}
          }     
    `}</script>
      <script type='application/ld+json'>{`
         "@context": "https://schema.org/", 
         "@type": "Product", 
         "name": "Tallarines panqueques con salsa salmón",
         "image": "https://www.cheffify.cl/static/media/tallarinespanquequessalmon.c32a95a54c21bb532ada.jpg",
         "description": "Salmón que compramos para esta salsa es elegido personalmente, le damos el corte perfecto y la sazón para hacer una experiencia en tu boca",
         "brand": {
           "@type": "Brand",
           "name": "Cheffify"
         },
         "offers": {
           "@type": "Offer",
           "url": "https://www.cheffify.cl/shop",
           "priceCurrency": "CLP",
           "price": "6450"}
         }
    `}</script>
      <script type='application/ld+json'>{`
         "@context": "https://schema.org/", 
         "@type": "Product", 
         "name": "Tallarines panqueques con salsa pollo",
         "image": "https://www.cheffify.cl/static/media/tallarinesdepanquequesalsapollo.6eb9419d74fbae59fbeb.jpg",
         "description": "Cortamos y hacemos estos maravillosos tallarines con nuestros panqueques, nuestra salsa es de pollo natural",
         "brand": {
           "@type": "Brand",
           "name": "Cheffify"
         },
         "offers": {
           "@type": "Offer",
           "url": "https://www.cheffify.cl/shop",
           "priceCurrency": "CLP",
           "price": "3950"}
         }    
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Strogonoff de pollo",
          "image": "https://www.cheffify.cl/static/media/strogonoffpollo.64c46f28c71c58286abc.jpg",
          "description": "Este strogonoff es muy liviano, la salsa y el corte del pollo lo hace un plato suave y muy delicioso, con una sazón delicada que le da un gusto especial a la boca",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "4450"}
          }
    `}</script>
      <script type='application/ld+json'>{`
         "@context": "https://schema.org/", 
         "@type": "Product", 
         "name": "Quiche pollo pimentón",
         "image": "https://www.cheffify.cl/static/media/quichepollopimenton.5935be422d143295dfda.jpg",
         "description": "A nuestra deliciosa quiche de pollo le quisimos agregar un poco de color y sabor con un toque de pimenton fresco",
         "brand": {
           "@type": "Brand",
           "name": "Cheffify"
         },
         "offers": {
           "@type": "Offer",
           "url": "https://www.cheffify.cl/shop",
           "priceCurrency": "CLP",
           "price": "3950"}
         }
    `}</script>
      <script type='application/ld+json'>{`
         "@context": "https://schema.org/", 
         "@type": "Product", 
         "name": "Quiche jamón queso",
         "image": "https://www.cheffify.cl/static/media/quichejamonqueso.ca3bb68bae3f2ac4e19f.jpg",
         "description": "Acompañala con un buen vino , la masa muy delgada, usamos queso muy suave, puedes trocearla para aperitivo o plato único",
         "brand": {
           "@type": "Brand",
           "name": "Cheffify"
         },
         "offers": {
           "@type": "Offer",
           "url": "https://www.cheffify.cl/shop",
           "priceCurrency": "CLP",
           "price": "3950"}
         }
    `}</script>
      <script type='application/ld+json'>{`
         "@context": "https://schema.org/", 
         "@type": "Product", 
         "name": "Quiche de Verdura",
         "image": "https://www.cheffify.cl/static/media/quicheverduras.72e2d721f730a202c332.jpg",
         "description": "Plato vegetariano, liviano con verduras frescas y masa muy delgada",
         "brand": {
           "@type": "Brand",
           "name": "Cheffify"
         },
         "offers": {
           "@type": "Offer",
           "url": "https://www.cheffify.cl/shop",
           "priceCurrency": "CLP",
           "price": "3950"}
         }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Pollo con tocino",
          "image": "https://www.cheffify.cl/static/media/pollotocino.1941d0043afdedc595dc.jpg",
          "description": "Nos destacamos especialmente por la cocción de nuestros pollos, nuestro corte de pechuga deshuesada envuelta en fino tocino, quedando dorado por fuera y muy jugoso",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "4450"}
          }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Pollo arvejado",
          "image": "https://www.cheffify.cl/static/media/polloarvejado.e8c37bec703b55169e9f.jpg",
          "description": "Pollo con ese sabor tan particular de nuestras abuelas, la arveja tierna, con el corte de la zanahoria fina, y muy jugoso",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "4450"}
          }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Pie de pollo",
          "image": "https://www.cheffify.cl/static/media/piedepollo.fad365875a3fe9e9fbfc.jpg",
          "description": "La masa que hacemos en este pie es tan suave y delgada que puedes tener esa mezcla de sabores en tu boca del pollo jugoso y salteado",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "3950"}
          }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Picante de camarón",
          "image": "https://www.cheffify.cl/static/media/picantecamaron.ac19dcee7d562fe8144c.jpg",
          "description": "Es una receta exclusiva nuestra, de los platos más vendidos, son camarones ecuatorianos de 36/40, con una salsa inigualable, acompañalo con arroz blanco",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "7850"}
          }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Pastelera",
          "image": "https://www.cheffify.cl/static/media/pastelera.7139f5d524c7f235242f.jpg",
          "description": "Esta pastelera es perfecta para acompañar alguna carne roja o blanca, está hecha con choclo tierno, le damos una sazón natural para que no pierda su sabor original",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "3300"}
          }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Pastel de choclo pollo",
          "image": "https://www.cheffify.cl/static/media/pasteldechoclopollo.c01d627573720a93535f.jpg",
          "description": "Al igual que el pastel de choclo carne, le hicimos esta variación en vez de carne le pusimos pollo, es muy suave y la combinación es perfecta, muy jugosa",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "3950"}
          }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Panqueques para rellenar (la docena)",
          "image": "https://www.cheffify.cl/static/media/panquequesrellenar.55a93c12b29167c06bc4.jpg",
          "description": "Delgados, suaves, te ayudan en cualquier comida dulce o salada, los mejores, en nuestro instagram te daremos muchas ideas para usarlos",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "3300"}
          }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Pan de pascua",
          "image": "https://www.cheffify.cl/static/media/pan_pascua.2578b192387c8a089bdc.jpg",
          "description": "El clásico pan de pascua, con su mezcla perfecta con almendras y nueces, especias y sabores navideños. Disfruta de su textura esponjosa y su aroma inigualable, en cada rebanada. (800 gr).",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "10500"}
          }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Lasaña pollo espinaca",
          "image": "https://www.cheffify.cl/static/media/lasanapolloespinaca.df2cd087eac536ca2faa.jpg",
          "description": "A nuestra espectacular lasaña de pollo le agregamos espinaca fresca, elegimos muy bien nuestras verduras, para que no se occiden en su cocción",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "3950"}
          }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Lasaña de pollo",
          "image": "https://www.cheffify.cl/static/media/lasanapollo.2f41c04ea9b497eadda8.jpg",
          "description": "El secreto de nuestro recetario familiar está en la salsa bechamel, es única y le da ese toque más cremoso y suave, en cuanto a la masa, somos italianas",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "3950"}
          }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Lasaña boloñesa",
          "image": "https://www.cheffify.cl/static/media/lasanabolognesapastapicada.fbba2bcbf7073e357990.jpg",
          "description": "Dirán esta lasaña la hace todo el mundo y es cierto, pero la nuestra es la más similar a la verdadera lasaña italiana",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "3950"}
          }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Galletas finas",
          "image": "https://www.cheffify.cl/static/media/surtido_galletas.daa792c758b25a13b403.jpg",
          "description": "Galletas finas crujientes por fuera, suaves por dentro. Saborea nuestros dulces sabores y disfruta de un momento ideal para tus tardes de té (1 KG Surtido).",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "17000"}
          }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Croquetas de atún",
          "image": "https://www.cheffify.cl/static/media/croquetasatun.2f4d5f509b3d35bc8285.jpg",
          "description": "Suave atún con aliños en su medida, quedan doradas por fuera y al partirlas veras el misterio de como quedan por dentro, atrévete",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "4450"}
          }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Crema de Zapallo",
          "image": "https://www.cheffify.cl/static/media/cremazapallo.2a0db789c4757e47c32d.jpg",
          "description": "Zapallo natural molido, le damos una cocción tan especial que se asoma un poco el dulzor y cremosidad perfecta, pruebala invierno y verano",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "3300"}
          }
    `}</script>
      <script type='application/ld+json'>{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Alfajor con chocolate",
          "image": "https://www.cheffify.cl/static/media/alfajores.9bda945c6001f341470e.jpg",
          "description": "Deliciosos alfajores de chocolate con suave relleno de manjar, el equilibrio perfecto entre dulzura y sabor que te hará desear más (8 Unidades).",
          "brand": {
            "@type": "Brand",
            "name": "Cheffify"
          },
          "offers": {
            "@type": "Offer",
            "url": "https://www.cheffify.cl/shop",
            "priceCurrency": "CLP",
            "price": "7900"}
          }
    `}</script>
    </Helmet>
  )
}

export default MetaTagsShop
