import styled from 'styled-components'
import { COLORS } from '../../../theme/css/global.colors'
import { device } from '../../../theme/css/media.querys'

export const BannerDiv = styled.div`
  padding: 85px 0px 35px 60px;
  background-image: url(${props => (props.bgImage ? props.bgImage : '')});
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  @media ${device.ipad} {
    padding: 35px;
  }
  .title {
    color: ${COLORS.white};
    margin-bottom: 20px;
  }
  .description {
    color: ${COLORS.white};
    margin-bottom: 40px;
    max-width: 780px;
    width: 100%;
    font-size: 17px;
    line-height: 25px;
  }
`

export const VentajasDiv = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${COLORS.roseLight};
  margin-top: 100px;
  padding-bottom: 120px;
  padding-top: 100px;
  @media ${device.ipad} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .content {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin-top: 60px;
    @media ${device.ipad} {
      flex-direction: column;
      justify-content: center;
    }
    &__image {
      margin-right: 60px;
      @media ${device.ipad} {
        margin-right: 0px;
      }
      img {
        width: 100%;
      }
    }
    &__information {
      @media ${device.ipad} {
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 30px;
      }
      &-list {
        list-style: disc;
        li {
          font-size: 17px;
          color: ${COLORS.violetDark};
          margin-bottom: 30px;
          strong {
            padding-right: 5px;
          }
        }
      }
    }
  }
  .bgLeft {
    position: absolute;
    top: 40px;
    left: 0;
    width: 75px;
    height: 311px;
    @media ${device.ipad} {
      display: none;
    }
  }
  .bgRight {
    position: absolute;
    right: 0;
    width: 196px;
    height: 290px;
    @media ${device.ipad} {
      display: none;
    }
  }
`

export const PreparacionDiv = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${COLORS.white};
  padding-bottom: 60px;
  padding-top: 60px;
  .content {
    &__title {
      text-align: center;
      margin-bottom: 60px;
      font-size: 42px;
      font-weight: 800;
      color: ${COLORS.violetDark};
    }
    &__list {
      color: ${COLORS.violetDark};
      li {
        list-style: disc;
        font-weight: lighter;
        margin-bottom: 30px;
        strong {
          padding-right: 5px;
        }
      }
    }
    &__image {
      width: 100%;
      img {
        display: block;
        width: 100%;
      }
    }
  }
`
