import { useState, useEffect } from 'react'
// Redux
import { useSelector } from 'react-redux'
// Molecules
import InputForm from '../../molecules/InputForm'
import Button from '../../molecules/Button'
import ErrorLabel from '../../molecules/ErrorLabel'
// Hooks
import { useNotify } from '../../../hooks/useToastNotification'
// Utils
import { totalWithDelivery } from '../../../utils/functions'
import { PRICE_DELIVERY } from '../../../utils/const'
import { isEmailValid } from '../../../utils/validators'
import { COLORS } from '../../../theme/css/global.colors'
// Styles
import Form from './styles'
import SelectForm from '../../molecules/SelectForm'

const AddressForm = () => {
  const [address, setAddress] = useState(null)
  const [name, setName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [email, setEmail] = useState(null)
  const [comuna, setComuna] = useState(null)
  const [phone, setPhone] = useState(null)
  // Errors
  const [addressError, setAddressError] = useState(null)
  const [nameError, setNameError] = useState(null)
  const [lastNameError, setLastNameError] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [comunaError, setComunaError] = useState(null)
  const [phoneError, setPhoneError] = useState(null)

  const [preferenceId, setPreferenceId] = useState(null)

  const dataCesta = useSelector(state => state.products.dataCesta)

  useEffect(() => {
    // Llama a tu backend para obtener la preferencia de pago
    fetch('https://cheffify.cl/mercadopago/create_preference.php')
      .then(response => response.json())
      .then(data => {
        setPreferenceId(data.preference_id)
      })
      .catch(error => console.error('Error:', error))
  }, [])

  const handleSubmitForm = () => {
    if (dataCesta.length > 0) {
      const amount = totalWithDelivery(dataCesta, PRICE_DELIVERY)
      window.location.href = `https://cheffify.cl/transbank/transbank.php?action=create&amount=${amount}&address=${address}&name=${name}&lastName=${lastName}&email=${email}&comuna=${comuna.toLowerCase()}&phone=${phone}&products=${JSON.stringify(
        dataCesta
      )}`
    } else {
      useNotify({
        message: 'Debes elegir productos',
        type: 'error',
        close: 2000,
      })
    }
  }

  const handleSubmitFormMercadoPago = () => {
    window.open(
      `https://www.mercadopago.cl/checkout/v1/redirect?pref_id=${preferenceId}`,
      '_blank'
    )
  }

  const handleValidForm = (e, methodPayment) => {
    e.preventDefault()
    !address ? setAddressError(true) : setAddressError(false)
    !name ? setNameError(true) : setNameError(false)
    !lastName ? setLastNameError(true) : setLastNameError(false)
    !email ? setEmailError(true) : setEmailError(false)
    isEmailValid(email) ? setEmailError(false) : setEmailError(true)
    !comuna ? setComunaError(true) : setComunaError(false)
    !phone ? setPhoneError(true) : setPhoneError(false)

    if (address && name && lastName && email && comuna && phone) {
      methodPayment === 'webpay'
        ? handleSubmitForm()
        : handleSubmitFormMercadoPago()
    }
  }

  return (
    <Form>
      <h5 className='title'>Dirección de envío</h5>
      <InputForm
        type='text'
        name='nameAddress'
        id='nameAddress'
        placeholder='Dirección'
        onChangeInput={setAddress}
        error={addressError}
      />
      {addressError === true && <ErrorLabel text='Campo obligatorio' />}
      <div className='row'>
        <div>
          <InputForm
            type='text'
            name='nameAddress'
            id='nameAddress'
            placeholder='Nombre'
            onChangeInput={setName}
            marginRight={3}
            error={nameError}
          />
          {nameError === true && <ErrorLabel text='Campo obligatorio' />}
        </div>
        <div>
          <InputForm
            type='text'
            name='nameAddress'
            id='nameAddress'
            placeholder='Apellidos'
            onChangeInput={setLastName}
            marginLeft={3}
            error={lastNameError}
          />
          {lastNameError === true && <ErrorLabel text='Campo obligatorio' />}
        </div>
      </div>
      <InputForm
        type='text'
        name='nameAddress'
        id='nameAddress'
        placeholder='Email'
        onChangeInput={setEmail}
        error={emailError}
      />
      {emailError === true && <ErrorLabel text='Formato email incorrecto' />}
      <SelectForm
        type='text'
        name='nameAddress'
        id='nameAddress'
        placeholder='Comuna'
        onChangeSelect={setComuna}
        error={comunaError}
      />
      {comunaError === true && <ErrorLabel text='Campo obligatorio' />}
      <InputForm
        type='text'
        name='nameAddress'
        id='nameAddress'
        placeholder='Teléfono'
        marginLeft={3}
        onChangeInput={setPhone}
        error={phoneError}
      />
      {phoneError === true && <ErrorLabel text='Campo obligatorio' />}
      <Button
        text='Finalizar con WebPay'
        fontSize={17}
        color={COLORS.white}
        bgColor={COLORS.salmon}
        borderColor={COLORS.salmon}
        borderRadius={6}
        customStyle={{ marginTop: 10 }}
        onClick={e => handleValidForm(e, 'webpay')}
      />

      <Button
        text='Finalizar con MercadoPago'
        fontSize={17}
        color={COLORS.white}
        bgColor={COLORS.salmon}
        borderColor={COLORS.salmon}
        borderRadius={6}
        customStyle={{ marginTop: 10, display: 'none' }}
        onClick={e => handleValidForm(e, 'mercadopago')}
      />
    </Form>
  )
}

export default AddressForm
