import styled from 'styled-components'
// Utils
import { COLORS } from '../../../../theme/css/global.colors'
import { device } from '../../../../theme/css/media.querys'

const Container = styled.div`
  margin-top: 100px;
  margin-bottom: 50px;
  .title {
    color: ${COLORS.violetDark};
    font-size: 42px;
    text-align: center;
  }
  .container {
    @media ${device.ipad} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__block {
      margin-top: 50px;
      &-icon {
        width: 100%;
        display: block;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
      }
      /* SIZES ICONS */
      &-icon.iconFirst {
        max-width: 60px;
      }
      &-icon.iconSecond {
        max-width: 70px;
      }
      &-icon.iconThird {
        max-width: 85px;
      }
      &-icon.iconFour {
        max-width: 43px;
      }
      &-title {
        font-size: 18px;
        color: ${COLORS.violetDark};
        margin-bottom: 15px;
        text-align: center;
      }
      &-description {
        font-size: 14px;
        color: ${COLORS.violetDark};
        width: 100%;
        max-width: 235px;
        text-align: center;
      }
    }
  }
`
export default Container
