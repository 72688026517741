import { useDispatch } from 'react-redux'
import { resetProducts } from '../../../../redux/productosSlice'
// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react'
// import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
// import required modules
import { Pagination, Autoplay } from 'swiper'
// Texts
import { BANNER_INFO } from '../../../templates/Shop/text'
// Styled
import BoxCarousel, { Slider } from './styles'

const CarouselProductos = ({ filtersActive, setFiltersActive }) => {
  const dispatch = useDispatch()
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>'
    },
  }

  const handleClickButton = category => {
    if (category) {
      if (filtersActive.category !== category) {
        setFiltersActive({
          ...filtersActive,
          category,
        })
      } else {
        setFiltersActive({
          ...filtersActive,
          category: '',
          price: [1000, 25500],
        })
        dispatch(resetProducts())
      }
    }
  }

  return (
    <BoxCarousel>
      <Swiper
        pagination={pagination}
        modules={[Pagination, Autoplay]}
        autoplay={{
          delay: 10000,
        }}
      >
        {BANNER_INFO &&
          BANNER_INFO.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Slider
                  bannerDesktop={item.banner}
                  bannerMobile={item.bannerMobile}
                >
                  <div className='containerSlider'>
                    {index === 0 ? (
                      <h2
                        className='containerSlider__title'
                        style={{
                          color: item.colorText,
                        }}
                      >
                        {item.title}
                        <img
                          className='containerSlider__title-icon'
                          src={item.icon.type}
                          alt='Icono'
                        />
                      </h2>
                    ) : (
                      <h3
                        className='containerSlider__title'
                        style={{
                          color: item.colorText,
                        }}
                      >
                        {item.title}
                        <img
                          className='containerSlider__title-icon'
                          src={item.icon.type}
                          alt='Icono'
                        />
                      </h3>
                    )}
                    <p
                      className='containerSlider__description'
                      id='carta'
                      style={{
                        color: item.colorText,
                      }}
                    >
                      {item.description}
                    </p>
                    <a
                      className='containerSlider__btn'
                      style={{
                        backgroundColor: item.bgColorBtn,
                        color: item.colorTxtBtn,
                      }}
                      href='#carta'
                      onClick={() => handleClickButton(item.category)}
                    >
                      {item.txtBtn}
                    </a>
                  </div>
                </Slider>
              </SwiperSlide>
            )
          })}
      </Swiper>
    </BoxCarousel>
  )
}

export default CarouselProductos
