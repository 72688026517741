import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// Redux
import { useSelector } from 'react-redux'
// Molecules
import Button from '../../molecules/Button'
// Hooks
import { useNotify } from '../../../hooks/useToastNotification'
// Utils
import { totalWithDelivery } from '../../../utils/functions'
import { PRICE_DELIVERY } from '../../../utils/const'
import { COLORS } from '../../../theme/css/global.colors'
// Styles
import { SelectContainer } from './styles'

const SelectAddress = () => {
  const navigate = useNavigate()
  const [address, setAddress] = useState([])
  const [addressSelected, setAddressSelected] = useState('')
  const [comunaSelected, setComunaSelected] = useState('')

  const dataCesta = useSelector(state => state.products.dataCesta)
  const dataUser = JSON.parse(sessionStorage.getItem('dataUser'))

  const fetchGetAddress = () => {
    fetch(
      `https://cheffify.cl/administrator/login/address.php?id=${dataUser[0].data.id}`
    )
      // Exito
      .then(response => response.json()) // convertir a json
      .then(result => {
        setAddress(result)
        setAddressSelected(result[0].address)
        setComunaSelected(result[0].comuna)
      })
      .catch(err => console.log('Solicitud fallida', err))
  }

  const handleChangeSelect = event => {
    const value = JSON.parse(event.target.value)
    setAddressSelected(value.name)
    setComunaSelected(value.comuna)
  }

  const handleSubmitForm = () => {
    if (dataCesta.length > 0) {
      // Set Values to pay
      const amount = totalWithDelivery(dataCesta, PRICE_DELIVERY)
      const idUser = dataUser[0].data.id
      const name = dataUser[0].data.nombre
      const lastName = dataUser[0].data.apellido
      const email = dataUser[0].data.email
      const phone = dataUser[0].data.celular

      window.location.href = `https://cheffify.cl/transbank/transbank.php?action=create&amount=${amount}&idUser=${idUser}&address=${addressSelected}&name=${name}&lastName=${lastName}&email=${email}&comuna=${comunaSelected}&phone=${phone}&products=${JSON.stringify(
        dataCesta
      )}`
    } else {
      useNotify({
        message: 'Debes elegir productos',
        type: 'error',
        close: 2000,
      })
    }
  }

  useEffect(() => {
    fetchGetAddress()
  }, [])

  return (
    <SelectContainer>
      <h5 className='title'>Dirección de envío</h5>
      {address.length > 0 && (
        <select className='selectAddress' onChange={handleChangeSelect}>
          {address.map(item => (
            <option
              key={item.id}
              value={JSON.stringify({
                name: item.address,
                comuna: item.comuna,
              })}
            >
              {item.name}
            </option>
          ))}
        </select>
      )}
      {address.length > 0 ? (
        <p className='selectedText'>
          {addressSelected} - {comunaSelected}
        </p>
      ) : (
        <p className='selectedText'>
          Debe ir a mi cuenta a crear una dirección
        </p>
      )}

      <Button
        text='Agregar dirección'
        fontSize={12}
        color={COLORS.aqua}
        bgColor={COLORS.white}
        borderColor={COLORS.aqua}
        iconLeftSize={25}
        width={135}
        customStyle={{ padding: '6px', marginBottom: 23 }}
        onClick={() => navigate('/perfil')}
      />
      <Button
        fontSize={15}
        borderRadius={6}
        text='Continuar'
        onClick={handleSubmitForm}
      />
    </SelectContainer>
  )
}

export default SelectAddress
